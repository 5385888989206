import { notification } from "antd";

export const buildDataFromDocs = (docs: any) => {
  let data: any = {};
  docs.forEach((doc: any) => {
    data[doc.id] = doc.data();
  });
  return data;
};

export const generateTimeToken = (minute: any) => {
  const timeToken = new Date().getTime() + minute * 60000;
  return Buffer.from(`time=${timeToken}`).toString("base64");
};

export const generateDraftUrl = (jobId:any,minute:any)=>{
  const token = generateTimeToken(minute);
  return `${getWebUrl()}/job/${jobId}?token=${token}`;
}

export const getWebUrl = () => {
  return process.env.REACT_APP_WEBAPP
};

export const buildResultsDocs = (docs: any) => {
  const data: any = [];
  docs.forEach((doc: any) => {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
};

export const catchExceptionCallback = (error: any) => {
  let error1 = error;
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      error1 = error.response.data.message;
    } else {
      error1 = error.response.data;
    }
  } else if (error.message) {
    error1 = error.message;
  }
  notification.error({ message: error1 });
  return error;
};

export const successNotification = (message: any) => {
  notification.success({ message });
};

export const prefectureList = [
  "Hokkaido",
  "Aomori",
  "Iwate",
  "Miyagi",
  "Akita",
  "Yamagata",
  "Fukushima",
  "Ibaraki",
  "Tochigi",
  "Gunma",
  "Saitama",
  "Chiba",
  "Tokyo",
  "Kanagawa",
  "Niigata",
  "Toyama",
  "Ishikawa",
  "Fukui",
  "Yamanashi",
  "Nagano",
  "Gifu",
  "Shizuoka",
  "Aichi",
  "Mie",
  "Shiga",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Nara",
  "Wakayama",
  "Tottori",
  "Shimane",
  "Okayama",
  "Hiroshima",
  "Yamaguchi",
  "Tokushima",
  "Kagawa",
  "Ehime",
  "Kochi",
  "Fukuoka",
  "Saga",
  "Nagasaki",
  "Kumamoto",
  "Oita",
  "Miyazaki",
  "Kagoshima",
  "Okinawa",
];

export const statusOfResidenceList = [
  "studyAbroad",
  "permanentResidents",
  "visaLongTermResident",
  "designatedActivities",
  "spouseOfJapanese",
  "spouseOfPermanentResident",
  "familyResidency",
  "shortTermActivities",
  "techincalInternship",
  "diplomacy",
  "official",
  "teaching",
  "press",
  "religion",
  "managementAndAdminstration",
  "visaInstructor",
  "legalAndAccountingServices",
  "highlySpecilizedProffesion",
  "research",
  "education",
  "technical",
  "humanitiesAndInternationalServices",
  "entertainment",
  "technicalSkills",
  "medicalCare",
  "arts",
  "nursingCare",
  "culturalActivities",
  "visaTrainee",
  "visaIntraCompanyTransferee",
  "visaTemporaryVisitor"
];

export const japaneseLevelList = ["N1", "N2", "N3", "N4", "N5", "N0"];

export const USER_ROLES = {
  admin: { label: "Admin", value: "company/admin" },
  owner: { label: "Owner", value: "company/owner" },
  public: { label: "Public", value: "company/editor" },
};

export const ROLES = {
  ADMIN: USER_ROLES.admin.value,
  OWNER: USER_ROLES.owner.value,
  PUBLIC: USER_ROLES.public.value,
};
