import React from "react";
import { Col, Row, Modal } from "antd";
import "../../assets/styles/style.css"
import { useTranslation } from 'react-i18next'

interface Props {
  success: boolean;
  modalStatus: boolean;
  handleModalClose: any;
}

const ModalInvite = (props: Props) => {
  const { success, modalStatus, handleModalClose } = props;
  const { t } = useTranslation();

  const color = success ? "#5FA753" : "#ff3333";
  return (
    <Modal visible={modalStatus} onCancel={handleModalClose} footer={null}>
      <div className="invite-info-container">
        <Col>
          <Row
            style={{
              justifyContent: "center",
            }}
          >
            {success ? (
              <svg
                width="80"
                height="78"
                viewBox="0 0 55 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.7136 32.7489L34.7703 49.0647C35.2103 49.7797 35.8978 49.7714 36.1756 49.7329C36.4533 49.6944 37.1216 49.5239 37.3636 48.7127L49.9393 6.23893C50.1593 5.48818 49.7551 4.97668 49.5736 4.79518C49.3976 4.61368 48.8943 4.22593 48.1656 4.43218L5.6616 16.8787C4.85585 17.1152 4.67985 17.7917 4.64135 18.0694C4.60285 18.3527 4.59185 19.0539 5.3041 19.5022L21.8068 29.8229L36.3873 15.0884C37.1876 14.2799 38.4938 14.2717 39.3051 15.0719C40.1163 15.8722 40.1218 17.1812 39.3216 17.9897L24.7136 32.7489ZM35.9611 53.8744C34.0471 53.8744 32.2926 52.9009 31.2586 51.2289L20.5968 33.9287L3.11785 22.9974C1.2341 21.8177 0.249599 19.7167 0.554849 17.5084C0.857349 15.3002 2.3726 13.5457 4.5011 12.9214L47.0051 0.474933C48.9604 -0.0970671 51.0559 0.444683 52.4968 1.88018C53.9379 3.32943 54.4741 5.44693 53.8911 7.41043L41.3153 49.8814C40.6856 52.0182 38.9256 53.5279 36.7228 53.8222C36.4643 53.8552 36.2141 53.8744 35.9611 53.8744Z"
                  fill={color}
                />
              </svg>
            ) : (
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 1280.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <metadata></metadata>
                <g
                  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    fill={color}
                    d="M2321 12784 c-122 -33 -105 -17 -1184 -1093 -565 -565 -1041 -1046
                -1057 -1070 -94 -140 -103 -331 -23 -471 16 -28 702 -722 1877 -1897 l1851
                -1853 -1856 -1857 c-1511 -1512 -1860 -1867 -1878 -1906 -29 -64 -51 -152 -51
                -202 0 -59 27 -161 57 -219 39 -74 2085 -2120 2159 -2159 137 -72 291 -74 427
                -6 29 14 611 590 1899 1877 l1858 1857 1852 -1851 c1176 -1175 1870 -1861
                1898 -1877 149 -86 343 -70 487 38 32 23 513 499 1069 1056 765 768 1017 1026
                1037 1065 73 141 74 305 0 434 -16 28 -709 729 -1877 1898 l-1851 1852 1851
                1853 c1168 1168 1861 1869 1877 1897 74 129 73 293 0 434 -20 39 -272 297
                -1037 1065 -556 557 -1037 1033 -1069 1056 -144 108 -338 124 -487 38 -28 -16
                -722 -702 -1898 -1877 l-1852 -1851 -1858 1857 c-1288 1287 -1870 1863 -1899
                1877 -100 50 -219 63 -322 35z"
                  />
                </g>
              </svg>
            )}
          </Row>
          <Row className="invite-info-message-container">
            <h2 style={{ color, fontWeight: "bold" }}>
              {success ? t("Invitation sent") : "Something Went Wrong"}
            </h2>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};

export default ModalInvite;
