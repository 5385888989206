import React from "react";
import { StoreList } from "../../components/Store/List";
import DashboardLayout from "../Dashboard";

const List: React.FC = () => {
  return (
    <DashboardLayout>
      <StoreList />
    </DashboardLayout>
  );
};
export default List;
