import styled from 'styled-components';

export  const LoginStyles = {
    FormTitle: styled.h4`
    padding-top: 3em;
    font-size: 1.5rem;
    text-align: center;
`,
    FormContainer: styled.div`
    padding: 3em;

`,
    Label: styled.label`
    margin-top: 1em;
    font-weight: bold;
    margin-bottom: 1em;
    `,
    ButtonWrapper: styled.div`
    text-align: center;
    margin-top: 1.5em;
    `,
    ErrorText: styled.p`
    color: red;
    text-align: center;
    `,
    BrandImage: styled.div`
    text-align: center;
    `,
}
