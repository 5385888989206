import { t } from "i18next";
import * as yup from "yup";

export const TEXT_FIELD_VARIANT = "outlined";
export const INITIAL_DATA = {
  companyName: "",
  address: "",
  websiteURL: "",
  email: "",
  password: "",
  confirmPassword: "",
};
export const EDIT_INITIAL_DATA = {
  ...INITIAL_DATA,
  personInCharge: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const commonValidationSchema = {
  companyName: yup.string().required(t("Company Name is required")),
  address: yup.string().required(t("Address is Required")),
  websiteURL: yup
    .string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      t("Enter valid url")
    )
    .required(t("Website URL is required")),
};
export const updateValidationSchema = yup.object().shape({
  ...commonValidationSchema,
  personInCharge: yup
    .array()
    .min(1, t("Person In Charge is Required"))
    .required(t("Person In Charge is Required")),
});
