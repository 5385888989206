import i18n from "i18next"
import { initReactI18next } from "react-i18next";
import translationEn from './locales/en/translation.json'
import translationJa from './locales/ja/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
      translation: translationEn,
    },
    ja: {
      translation: translationJa,
    },
  };

  i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["ja", "en"],
    load: "languageOnly",
    lng:"ja",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: "|",
  });
  
export default i18n;

