import styled from "styled-components";

export const JobFormStyles = {
  JobForm: styled.div`
    background-color: #ffffff;
    .ant-typography {
      margin-bottom: 1.2rem;
    }
    .company-details,
    .employment-type,
    .applicant-requirements {
      margin-bottom: 2.4rem;
    }
    .applicant-requirements,
    .job-advertisement,
    .footer {
      width: 100%;
      max-width: 1200px;
      margin-top: 2.4rem;
    }
    .applicant-requirements > div {
      padding-left: 2em;
    }
    .ant-form-item-label {
      width: 12rem;
      font-weight: 500;
      text-align: left;
    }
    .resident-status {
      margin-bottom: 1rem;
    }
    .japanese-level {
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
    .table-body > .ant-col {
      padding-right: 1.5rem;
    }
    .table-body {
      margin: 1rem 0;
    }
    .job-advertisement .ant-tag {
      margin-bottom: 0.25rem;
    }
    .language-qualification{
      flex-direction: column;
    }
    .language-qualification .ant-form-item-explain-error {
      margin-bottom: 1.5em;
    }
  `,

  Label: styled.h4`
    font-style: normal;
    font-size: 20px;
    line-height: 16px;
    color: #000000;
  `,
  Sublabel: styled.p`
    font-size: 16px;
    font-weight: 500;
  `,

  FormContainer: styled.div`
    padding: 0em 2em 0em 2em;
  `,

  Attributes: styled.p`
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 14px;
    padding-top: 1em;
  `,

  ListItem: styled.li`
    font-size: 14px;
    font-weight: 500;
    color: #5f5d5d;
    letter-spacing: 0.2px;
  `,
  SubForm: styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    padding: 0.6em 1.3em;
    margin-bottom: 1em;
    .ant-checkbox-wrapper,
    .ant-tag {
      padding: 0.25rem 0.5rem;
    }
  `,
  ItemsGroup: styled.div`
    display: grid;
  `,
  Items: styled.span`
    background-color: #645a82;
    padding: 6px;
    border-radius: 1em;
  `,

  RowForm: styled.div`
        border 1px solid #e1e1e1;
        padding: 1.3rem;
        border-radius: 6px;
        margin-bottom: 1.2rem;
    `,
};

export const FormComponents = {
  SelectDisabled: {
    borderRadius: "1em",
    minWidth: "100%",
    backgroundColor: "transparent",
  },
  Select: {
    borderRaduis: "1em",
    backgroundColor: "#ffffff",
    minWidth: "100%",
  },
};
