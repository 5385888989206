import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import { useFormik } from "formik";
import DashboardLayout from "../Dashboard";
import styled from "styled-components";
import * as Yup from "yup";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../firebase";
import { catchExceptionCallback, successNotification } from "../../utils";
import { signOut } from "firebase/auth";
import { AuthContext } from "../../ContextApi/AuthContext";

const ChangePasswordCard = styled(Card)`
  width: 100%;
  .hasError p {
    margin-bottom: 0px;
    color: red;
    position: absolute;
  }
`;
const ChangePassword: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    newPassword: Yup.string().required(t("Password is required")),
    currentPassword: Yup.string().required(t("Current password is required")),
    confirmNewPassword: Yup.string()
      .required(t("Confirm password is required"))
      .oneOf([Yup.ref("newPassword"), null], t("Password do not match")),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (_data: any, { resetForm }) => {
      try {
        setLoading(true);
        const myUser: any = auth.currentUser;
        const currentPassword: any = _data?.currentPassword;
        const newPassword: any = _data?.newPassword;
        const cred: any = EmailAuthProvider.credential(
          myUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(myUser, cred);
        await updatePassword(myUser, newPassword);
        successNotification("Password changed Successfully");
        handleSignOut();
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        resetForm({
          values: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          },
        });
        setLoading(false);
      }
    },
  });

  interface ReturnType {
    error: "error" | "";
    message: string;
  }

  const haveError = (keyName: string): ReturnType => {
    let error: any = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? "error"
      : "";
    let message: any = error ? formik.errors[keyName] : "";

    let errorObj: ReturnType = {
      error,
      message,
    };
    
    return errorObj;
  };

  const handleSignOut = async () => {
    await signOut(auth);
    setUser(null);
    history.push("/");
  };

  return (
    <DashboardLayout>
      <ChangePasswordCard>
        <form>
          <Row>
            <Col span={24}>
              <Spin spinning={loading}>
                <Row>
                  <Form>
                      <Form.Item
                        label={t("Current Password")}
                        validateStatus={haveError("currentPassword").error}
                        help={haveError("currentPassword").message}
                        required
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          id="currentPassword"
                          type="password"
                          value={formik.values.currentPassword}
                          name="currentPassword"
                          onChange={formik.handleChange}
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    <Col span={24}>
                      <Form.Item
                        label={t("New Password")}
                        validateStatus={haveError("newPassword").error}
                        help={haveError("newPassword").message}
                        required
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          id="newPassword"
                          name="newPassword"
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          type="password"
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={t("Confirm Password")}
                        validateStatus={haveError("confirmNewPassword").error}
                        help={haveError("confirmNewPassword").message}
                        required
                        labelCol={{ span: 10 }}
                      >
                        <Input
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          value={formik.values.confirmNewPassword}
                          onChange={formik.handleChange}
                          type="password"
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item wrapperCol={{ offset: 14, span: 3 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                          onClick={() => formik.handleSubmit()}
                        >
                          {t("Update")}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Form>
                </Row>
              </Spin>
            </Col>
          </Row>
        </form>
      </ChangePasswordCard>
    </DashboardLayout>
  );
};

export default ChangePassword;
