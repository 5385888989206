import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  LoginOutlined,
  UsergroupDeleteOutlined,
  HomeOutlined,
  LockOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Layout, Menu, Row } from "antd";
import styled from "styled-components";
import { DashBoardContext } from "../ContextApi/DashBoardContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../ContextApi/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import BrandImage from "../assets/image/brand.png";
import ProfileIcon from "../assets/image/profile-icon.svg";
import { USER_ROLES } from "../utils";

const { Header, Sider, Content } = Layout;

const LogoContainer = styled.div`
  text-align: center;
  display: flex;
  background-color: #e26e30;
  padding: 2em;
`;

const ProfileContainer = styled.div`
  margin-right: 25px;
  padding-top: 0;
  .leftMargin{
    margin-left:10px;
  }
`

const PageHeader = styled.h3`
  margin-left: 2em;
  color: black;
  font-weight: 700;
`;

const DashboardLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const { currentNavbar, setCurrentNavbar } = useContext(DashBoardContext);
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const { setUser, role } = useContext(AuthContext);

  const isAdmin = role === USER_ROLES.admin.value || role === USER_ROLES.owner.value;

  const handleSignOut = async () => {
    await signOut(auth);
    setUser(null);
    history.push("/");
  };

  const navItems = [
    {
      key: "jobs",
      icon: <UsergroupAddOutlined />,
      label: t("Jobs"),
      admin: false,
      function: () => history.push("/jobs"),
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: t("Users"),
      admin: true,
      function: () => history.push("/users"),
    },
    {
      key: "applications",
      icon: <UsergroupDeleteOutlined />,
      label: t("Applicants"),
      admin: false,
      function: () => history.push("/applications"),
    },
    {
      key: "stores",
      icon: <HomeOutlined />,
      label: t("Store Management"),
      admin: false,
      function: () => history.push("/stores"),
    },
    {
      key: "company-information",
      icon: <SettingOutlined />,
      label: t("Companies"),
      admin: true,
      function: () => history.push("/company-information"),
    },
    {
      key: "logout",
      icon: <LoginOutlined />,
      label: t("Logout"),
      admin: false,
      function: () => handleSignOut(),
    },
  ];

  const routes = [
    {
      label: t("Jobs"),
      key: "jobs",
    },
    {
      label: t("Users"),
      key: "users",
    },
    {
      label: t("Applicants"),
      key: "applications",
    },
    {
      label: t("Stores"),
      key: "stores",
    },
    {
      label: t("Companies"),
      key: "company-information",
    },
    {
      label: t("Change Password"),
      key: "change-password",
    },
    {
      label: t("My Profile"),
      key: "my-profile",
    }
  ]


  const handleNavigation = (e: any) => {
    setCurrentNavbar(e.key);
    const item = navItems.filter((item) => item.key === e.key)[0];
    item.function();
  };

  useEffect(() => {
    setCurrentNavbar(history.location.pathname.split("/")[1] ?? "jobs");
  }, [setCurrentNavbar, history.location.pathname]);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ height: "auto", minHeight: "100vh", overflow: "hidden" }}
      >
        <LogoContainer>
          {!collapsed && (
            <img
              src={BrandImage}
              alt="brand logo"
              style={{ height: "1.5em" }}
            />
          )}
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[`${currentNavbar}`]}
          style={{
            height: "100%",
            backgroundColor: "#39404f",
            color: "#FFFFFF",
          }}
          onClick={(e: any) => handleNavigation(e)}
          items={isAdmin ? navItems : navItems.filter(navItem => !navItem.admin)}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ padding: 0, background: "#F8F5EF" }}
        >
          <Row>
            <Col span={1}>
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    style: {
                      color: "black",
                      fontSize: "20px",
                      paddingLeft: "1em",
                    },
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </div>
            </Col>
            <Col span={10}>
              <div>
                <PageHeader>
                  {routes.filter((item) => item.key === currentNavbar.toString())[0]?.label}
                </PageHeader>
              </div>
            </Col>
            <Col span={1} offset={12}>

              <ProfileContainer>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <SolutionOutlined className="icon" />
                        <span className="leftMargin" onClick={() => history.push('/my-profile')}>{t('Profile')}</span>
                      </Menu.Item>
                      <Menu.Item key="display" onClick={() => { history.push('/change-password') }}>
                        <LockOutlined className="icon" />
                        <span className="leftMargin">{t('Change Password')}</span>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  arrow={{ pointAtCenter: true }}
                  placement="bottomRight"
                >
                  <div onClick={e => e.preventDefault()}>
                    <img
                      src={ProfileIcon}
                      alt="profile"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Dropdown>

              </ProfileContainer>
            </Col>
          </Row>
        </Header>
        <Content
          className=""
          style={{
            backgroundColor: "#FFFFFF",
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
