import React, { useEffect, useRef } from "react";
import { Button, Form, Input, Row, Col, TimePicker, Typography } from "antd";
import { JobFormStyles } from "./JobformStyles";
import moment from "moment";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ImageUpload } from "../ImageUpload/image-upload";
import {
  FORM_LEVEL_ERROR_MAP,
  MAX_DESCRIPTION_LENGTH,
  MAX_TEXT_LENGTH,
  MAX_TITLE_LENGTH,
} from "./helpers";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { TextArea } = Input;
interface Props {
  formik: any;
  mode: "English" | "Japanese";
  onWorkingHoursAdd: (mode?: any) => void;
  onWorkingHoursRemove: (index: number, mode?: any) => void;
  onWorkingHoursChange: (
    value: any,
    index: number,
    key: string,
    mode?: any
  ) => void;
  haveError: any;
}
const { Text } = Typography;

export const JobListingAdvertisementForm: React.FC<Props> = (props: Props) => {
  const { formik, mode, onWorkingHoursAdd, onWorkingHoursRemove, haveError } =
    props;
  const jobTitleTarget = mode === "English" ? "titleEn" : "title";
  const addressTarget = mode === "English" ? "addressEn" : "addressJa";
  const jobDescriptionTarget =
    mode === "English" ? "jobDescriptionEn" : "jobDescription";
  const jobTypeTarget = mode === "English" ? "jobTypeEn" : "jobType";
  const commentTarget = mode === "English" ? "commentEn" : "comment";
  const minSalaryTarget =
    mode === "English" ? "minHourlyWageEn" : "minHourlyWage";
  const maxSalaryTarget =
    mode === "English" ? "maxHourlyWageEn" : "maxHourlyWage";
  const salaryTarget = mode === "English" ? "salaryEn" : "salary";

  const { t } = useTranslation();

  const getFormLevelError = (mode: "english" | "japanese") => {
    let _formik = formik as any;
    const ENGLISH_KEYS = ["titleEn", "jobDescriptionEn"];
    const JAPANESE_KEYS = ["title", "jobDescription"];
    let KEYS: string[] = [];
    if (mode === "english") KEYS = ENGLISH_KEYS;
    else KEYS = JAPANESE_KEYS;
    let errorKeys: string[] = [];
    KEYS.forEach((key: string) => {
      if (_formik.errors[key] && _formik.touched[key]) errorKeys.push(key);
    });

    if (_formik.errors.workingHours) {
      _formik.errors.workingHours.forEach((object: any, index: any) => {
        if (!object) return;
        Object.keys(object).forEach((key) => {
          if (
            !_formik.touched.workingHours ||
            !_formik.touched.workingHours[index]
          )
            return;
          if (_formik.touched.workingHours[index][key]) errorKeys.push(key);
        });
      });
    }
    let missingLabels: string[] = [];
    errorKeys
      .filter((item) =>
        mode === "english" ? item.includes("En") : !item.includes("En")
      )
      .forEach((key) => {
        let label = FORM_LEVEL_ERROR_MAP[key] || "Hourly wage table";
        if (!missingLabels.includes(label)) missingLabels.push(label);
      });
    return missingLabels.length > 0
      ? `Values of: ${missingLabels.join(
          ", "
        )} are missing from ${mode} section form`
      : "";
  };

  useEffect(() => {
    formik.values.workingHours.forEach((_: unknown, index: string) => {
      if (formik.values.employmentType === "fullTime") {
        formik.setFieldValue(`workingHours.${index}.maxHourlyWageEn`, "");
        formik.setFieldValue(`workingHours.${index}.maxHourlyWage`, "");
        formik.setFieldValue(`workingHours.${index}.minHourlyWageEn`, "");
        formik.setFieldValue(`workingHours.${index}.minHourlyWage`, "");
      } else {
        formik.setFieldValue(`workingHours.${index}.salary`, "");
        formik.setFieldValue(`workingHours.${index}.salaryEn`, "");
      }
    });
  }, [formik.values.employmentType]);

  const quillRef: any = useRef(null);

  return (
    <>
      <Form.Item
        label={t("Job Title")}
        validateStatus={haveError(jobTitleTarget).error}
        help={haveError(jobTitleTarget).message}
        required
      >
        <TextArea
          rows={1}
          style={{ borderRadius: "5px" }}
          maxLength={MAX_TITLE_LENGTH}
          value={formik.values[jobTitleTarget]}
          onChange={(e) => formik.setFieldValue(jobTitleTarget, e.target.value)}
        />
      </Form.Item>

      <Form.Item
        label={t("Address")}
        validateStatus={haveError(addressTarget).error}
        help={haveError(addressTarget).message}
        required
      >
        <TextArea
          rows={1}
          style={{ borderRadius: "5px" }}
          maxLength={MAX_TITLE_LENGTH}
          value={formik.values[addressTarget]}
          onChange={(e) => formik.setFieldValue(addressTarget, e.target.value)}
        />
      </Form.Item>

      <Form.Item
        className="fitJobDescriptionContent"
        label={t("Job Description")}
        validateStatus={haveError(jobDescriptionTarget).error}
        help={haveError(jobDescriptionTarget).message}
        required
      >
        <ReactQuill
          className={
            haveError(jobDescriptionTarget).error ? "custom-error-class" : ""
          }
          ref={quillRef}
          theme="snow"
          value={formik.values[jobDescriptionTarget]}
          onChange={(value, delta, source, editor) => {
            if (source !== "user") return;
            let content = value;
            if (editor.getLength() > MAX_DESCRIPTION_LENGTH) {
              quillRef.current
                .getEditor()
                .deleteText(MAX_DESCRIPTION_LENGTH, editor.getLength());
              content = quillRef.current.getEditor().root?.innerHTML;
            }
            if (content.replace(/(<([^>]+)>)/gi, "").length == 0) content = "";
            formik.setFieldValue(jobDescriptionTarget, content);
          }}
          onKeyPress={(e) => {
            const jobDescriptionOnlyCharacters = formik.values[
              jobDescriptionTarget
            ].replace(/(<([^>]+)>)/gi, "");
            if (
              jobDescriptionOnlyCharacters?.length >
              MAX_DESCRIPTION_LENGTH - 1
            )
              e.preventDefault();
          }}
        />
      </Form.Item>

      {/* working hours */}
      <JobFormStyles.Attributes>
        {t("Hourly wage/work hours")}
      </JobFormStyles.Attributes>
      <Col span={24}>
        <JobFormStyles.RowForm>
          <Row>
            <Col sm={4} md={6}>
              {t("Job Type")}
            </Col>
            <Col sm={11} md={9} lg={6}>
              {t("Work Hours")}
            </Col>
            {formik.values.employmentType !== "fullTime" ? (
              <>
                <Col sm={3} md={4} lg={5}>
                  {t("Min Salary")}
                </Col>
                <Col sm={3} md={4} lg={5}>
                  {t("Max Salary")}
                </Col>
              </>
            ) : (
              <Col sm={6} md={8} lg={10}>
                {t("Salary")}
              </Col>
            )}
            <Col span={1}>{t("Action")}</Col>
          </Row>
          {formik.values.workingHours.map((item: any, index: number) => (
            <Row className="table-body" align="top" key={index}>
              <Col sm={4} md={6}>
                <Form.Item
                  validateStatus={
                    haveError("workingHours").forArray(index, jobTypeTarget)
                      .error
                  }
                  help={
                    haveError("workingHours").forArray(index, jobTypeTarget)
                      .message
                  }
                >
                  <TextArea
                    rows={1}
                    name={`workingHours.${index}.${jobTypeTarget}`}
                    maxLength={MAX_TEXT_LENGTH}
                    size="large"
                    style={{
                      border: "1px solid #AFB1B6",
                      borderRadius: "5px",
                    }}
                    value={item[jobTypeTarget]}
                    onChange={formik.handleChange}
                  />
                </Form.Item>
              </Col>
              <Col sm={11} md={9} lg={6}>
                <Form.Item
                  validateStatus={
                    haveError("workingHours").forArray(index, "from").error ||
                    haveError("workingHours").forArray(index, "to")
                  }
                  help={
                    haveError("workingHours").forArray(index, "from").message ||
                    haveError("workingHours").forArray(index, "from").message
                  }
                >
                  <TimePicker.RangePicker
                    size="large"
                    format="hh:mm a"
                    order={false}
                    style={{
                      border: "1px solid #AFB1B6",
                      borderRadius: "5px",
                    }}
                    onChange={(value) => {
                      formik.setFieldValue(
                        `workingHours.${index}.from`,
                        moment(value?.[0] ?? undefined)
                          .format("HH:mm")
                          .toString()
                      );
                      formik.setFieldValue(
                        `workingHours.${index}.to`,
                        moment(value?.[1] ?? undefined)
                          .format("HH:mm")
                          .toString()
                      );
                    }}
                    value={[
                      moment(item.from, "HH:mm").isValid()
                        ? moment(item.from, "HH:mm")
                        : null,
                      moment(item.to, "HH:mm").isValid()
                        ? moment(item.to, "HH:mm")
                        : null,
                    ]}
                  />
                </Form.Item>
              </Col>
              {formik.values.employmentType !== "fullTime" ? (
                <>
                  <Col sm={3} md={4} lg={5}>
                    <Form.Item
                      help={
                        haveError("workingHours").forArray(
                          index,
                          minSalaryTarget
                        ).message
                      }
                      validateStatus={
                        haveError("workingHours").forArray(
                          index,
                          minSalaryTarget
                        ).error
                      }
                    >
                      <TextArea
                        rows={1}
                        size="large"
                        maxLength={MAX_TEXT_LENGTH}
                        name={`workingHours.${index}.${minSalaryTarget}`}
                        style={{
                          border: "1px solid #AFB1B6",
                          borderRadius: "5px",
                        }}
                        value={item[minSalaryTarget]}
                        onChange={formik.handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={3} md={4} lg={5}>
                    <Form.Item
                      validateStatus={
                        haveError("workingHours").forArray(
                          index,
                          maxSalaryTarget
                        ).error
                      }
                      help={
                        haveError("workingHours").forArray(
                          index,
                          maxSalaryTarget
                        ).message
                      }
                    >
                      <TextArea
                        rows={1}
                        size="large"
                        maxLength={MAX_TEXT_LENGTH}
                        name={`workingHours.${index}.${maxSalaryTarget}`}
                        style={{
                          border: "1px solid #AFB1B6",
                          borderRadius: "5px",
                        }}
                        value={item[maxSalaryTarget]}
                        onChange={formik.handleChange}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col sm={6} md={8} lg={10}>
                    <Form.Item
                      validateStatus={
                        haveError("workingHours").forArray(index, salaryTarget)
                          .error
                      }
                      help={
                        haveError("workingHours").forArray(index, salaryTarget)
                          .message
                      }
                    >
                      <TextArea
                        rows={1}
                        size="large"
                        maxLength={MAX_TEXT_LENGTH}
                        name={`workingHours.${index}.${salaryTarget}`}
                        style={{
                          border: "1px solid #AFB1B6",
                          borderRadius: "5px",
                        }}
                        value={item[salaryTarget]}
                        onChange={formik.handleChange}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={1}>
                {formik.values.workingHours.length > 1 && (
                  <Button
                    danger
                    type="primary"
                    size="small"
                    onClick={() => onWorkingHoursRemove(index)}
                    icon={<CloseOutlined />}
                  />
                )}
              </Col>
              <Col span={22}>
                <Form.Item
                  validateStatus={
                    haveError("workingHours").forArray(index, commentTarget)
                      .error
                  }
                  help={
                    haveError("workingHours").forArray(index, commentTarget)
                      .message
                  }
                >
                  <TextArea
                    rows={2}
                    placeholder={t("Comments")}
                    name={`workingHours.${index}.${commentTarget}`}
                    style={{
                      border: "1px solid #AFB1B6",
                      borderRadius: "5px",
                    }}
                    value={item[commentTarget]}
                    onChange={formik.handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Row justify="center">
            <Button type="primary" onClick={() => onWorkingHoursAdd()}>
              {t("Add Row")}
            </Button>
          </Row>
        </JobFormStyles.RowForm>
        <Row>
          <Text type="danger">
            {getFormLevelError(mode === "Japanese" ? "english" : "japanese")}
          </Text>
        </Row>
      </Col>

      <Form.Item label={t("Image Import")}>
        <ImageUpload
          value={formik.values?.imageFile ?? ""}
          previewValue={formik.values?.imagePreviewUrl ?? ""}
          handleChange={(value) => {
            formik.setFieldValue("imagePreviewUrl", value.imagePreviewUrl);
            formik.setFieldValue("imageFile", value.imageFile);
          }}
        >
          <Button icon={<UploadOutlined />}>{t("Upload")} </Button>
        </ImageUpload>
      </Form.Item>
    </>
  );
};
