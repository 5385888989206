import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Card,
  Modal,
  Tag,
} from "antd";
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../Dashboard";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import {
  buildResultsDocs,
  catchExceptionCallback,
  generateDraftUrl,
  getWebUrl,
} from "../../utils";
import { Link, useHistory } from "react-router-dom";
import { t } from "i18next";
import styled from "styled-components";
import { AuthContext } from "../../ContextApi/AuthContext";
import { Job } from "../../constant/interfaces";
import { EMPLOYMENT_TYPE, INDUSTRIES } from "../../components/Job/helpers";

const HeaderCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  .header-card-box {
    display: flex;
    justify-content: space-between;
  }
  .header-card-box .header-card__search-input {
    width: 200px;
  }
  .mb-zero {
    margin-bottom: 0;
  }
`;

const getJobId = () => {
  return doc(collection(db, "Jobs")).id;
};

const JobList: React.FC = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [jobList, setJobList] = useState<any[]>([]);
  const [originalJobList, setOriginalJobList] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loadingState, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState<any>();

  useEffect(() => {
    const getJobs = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(
          query(
            collection(db, "Jobs"),
            where("companyId", "==", user?.companyId)
          )
        );
        setJobList(buildResultsDocs(querySnapshot));
        setOriginalJobList(buildResultsDocs(querySnapshot));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    getJobs();
  }, [user]);

  const onDelete = async (key: string) => {
    setDeleteModal(false);
    try {
      setLoading(true);
      await deleteDoc(doc(db, "Jobs", key));
      setJobList((prev) => prev.filter((item) => item.id !== key));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  const onJobDelete = (id: any) => {
    setSelectedJob(id);
    setDeleteModal(true);
  };

  const searchFrontend = () => {
    const newData = originalJobList.filter((item: any) =>
      Object.keys(item).some((k) =>
        String(item[k]).toLowerCase().includes(searchKeyword.toLowerCase())
      )
    );

    setJobList(newData);
  };

  const inputSearchChange = (e: any) => {
    setSearchKeyword(e.target.value || "");
    if (!e.target.value) setJobList(originalJobList);
  };

  const gotoDraftView = (id: string) => {
    window.open(generateDraftUrl(id, 1), "_blank", "noopener,noreferrer");
  };

  const generatePreview = (data: Job) => {
    if (data?.hasPublished) window.open(`${getWebUrl()}/job/${data?.id}`);
    else gotoDraftView(data?.id ?? "");
  };

  const columns = (onDelete: any) => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (jobId: string) => <>{jobId}</>,
    },
    {
      title: "タイトル",
      dataIndex: "",
      key: "タイトル",
      render: (data: any) => <>{data?.title ?? data?.draft?.title}</>,
    },
    {
      title: t("Business Type"),
      dataIndex: "businessType",
      key: "翻訳状況",
      render: (value: string, data: any) => (
        <>
          {
            INDUSTRIES.find(
              (item) => item.value === (value ?? data?.draft?.businessType)
            )?.label
          }
        </>
      ),
    },
    {
      title: t("Employment Type"),
      dataIndex: "employmentType",
      key: "isPublic",
      render: (employmentType: string, data: any) => (
        <>
          {
            EMPLOYMENT_TYPE.find(
              (item) =>
                item.value === (employmentType ?? data?.draft?.employmentType)
            )?.label
          }
        </>
      ),
    },
    {
      title: t("Page status"),
      key: "isPublic",
      render: (_: unknown, data: Job) => (
        <>
          {data?.hasPublished ? (
            <Tag color="green">{t("Published")}</Tag>
          ) : (
            <Tag color="red">{t("Draft")}</Tag>
          )}
        </>
      ),
    },
    {
      title: t("Action"),
      key: "action",
      render: (data: Job) => (
        <Space size="middle">
          <Tooltip title={t("Preview")}>
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => generatePreview(data)}
            />
          </Tooltip>
          <Link to={`jobs/${data.id}`}>
            <Tooltip title={t("Edit")}>
              <Button shape="circle" icon={<EditOutlined />} />
            </Tooltip>
          </Link>
          <Link to={`jobs/${getJobId()}#${data.id}`}>
            <Tooltip title={t("Copy")}>
              <Button shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </Link>
          <Tooltip placement="right" title={t("Delete")}>
            <Button
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onJobDelete(data.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <HeaderCard bordered={false}>
        <div className="header-card-box">
          <Form.Item label="キーワード" className="mb-zero" colon={false}>
            <Input.Group compact>
              <Input
                className="header-card__search-input"
                onChange={(e) => inputSearchChange(e)}
                onPressEnter={() => searchFrontend()}
                defaultValue=""
              />
              <Button onClick={() => searchFrontend()} type="primary">
                {t("Search")}
              </Button>
            </Input.Group>
          </Form.Item>
          <Button
            onClick={() => history.push(`/jobs/${getJobId()}`)}
            type="primary"
          >
            {t("Add")}
          </Button>
        </div>
      </HeaderCard>
      <Table
        loading={loadingState}
        dataSource={jobList}
        columns={columns(onDelete)}
      />
      <Modal
        title={t("Delete Confirmation")}
        visible={showDeleteModal}
        onOk={() => onDelete(selectedJob)}
        onCancel={() => setDeleteModal(false)}
        okText={t("confirm")}
        cancelText={t("cancel")}
      >
        <p>{t("Job Deletion Confirmation")}</p>
      </Modal>
    </DashboardLayout>
  );
};

export default JobList;
