import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { catchExceptionCallback, USER_ROLES } from "../utils";
import { doc, getDoc } from "firebase/firestore";
import { Spin } from "antd";

interface IUserProfile {
  companyId: string;
  companyName: string;
}
export interface IAuthProfile extends User, IUserProfile { }

type ContextProps = {
  loading: boolean;
  authenticated: boolean;
  user: IAuthProfile | null;
  role: string;
  setUser: (user: IAuthProfile | null) => void;
};

export const AuthContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<IAuthProfile | null>(null);
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const dispatchUser = () => {
    signOut(auth);
    setUser(null);
  }

  const getUserCompany = async (uid: string) => {
    const userSnap = await getDoc(doc(db, 'Admins', uid));
    const userData: any = userSnap.data();
    if (userData) {
      const companyId = userData.companyId;
      const companyData = await getDoc(doc(db, "Companies", companyId));
      return { ...companyData.data(), id: companyId }
    }
    return null;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const idTokenResult = await user!.getIdTokenResult();
          const role: any = idTokenResult.claims.role;
          const company: any = await getUserCompany(user.uid);
          if (!company?.isSuspended) {
            if (Object.values(USER_ROLES).some((r) => r.value === role)) {
              setUser({ ...user, companyId: company.id, companyName: company.companyName });
              setRole(role);
            } else {
              dispatchUser();
              catchExceptionCallback('Unauthorised User Access');
            }
          } else {
            dispatchUser();
            catchExceptionCallback('Account Suspended Please contact Adminstrator.');
          }
        }
        setLoading(false);
      } catch (error) {
        dispatchUser();
        catchExceptionCallback(error);
      }
    });
    return () => unsubscribe && unsubscribe();
  }, []);

  if(loading) {
    return <div className="spinner-box"><Spin spinning={loading} /></div>
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        authenticated: !!user,
        user,
        role,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
