import React, { useEffect, useImperativeHandle, useState } from "react";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import styled from "styled-components";
import {
  collection,
  orderBy,
  getDocs,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";

interface Props {
  columns: any;
  collectionName: any;
  callbackCollections?: any;
  filter?: Filter;
  pageLimit?: number;
  loading?: boolean;
  remove?: any;
}
interface Filter {
  key: string;
  value: any;
}
let data: any = [];

interface TabulateProps {
  remove: (id: string) => void;
}

const Tabulate: React.ForwardRefRenderFunction<TabulateProps, Props> = (
  {
    columns,
    collectionName,
    callbackCollections,
    filter,
    pageLimit = 10,
    loading = false,
  },
  ref
) => {
  const StyledDiv = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 2em;
  `;
  const ButtonContainer = styled.div`
    padding: 1em;
  `;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState({ prev: false, next: false });
  const [dataList, setDataList] = useState([] as any[]);
  const [mounted, setMounted] = useState(false);
  const [pagination, setPagination] = useState({
    hasPrevious: false,
    hasNext: true,
    pageSize: pageLimit,
    page: -1,
    lastVisible: {},
  });
  useEffect(() => {
    data[collectionName] = [];
    // eslint-disable-next-line
  }, [collectionName]);
  useEffect(() => {
    setMounted(true);
    next();
    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => {
    return {
      remove: (id: string) => {
        removeData(id);
      },
    };
  });

  let newPagination = pagination;
  const next = async () => {
    setLoading({ ...isLoading, next: true });
    var tabulateData = data[collectionName] || [];
    if (tabulateData[newPagination.page + 2] === undefined) {
      let q;
      if (Object.keys(newPagination.lastVisible).length !== 0) {
        if (filter) {
          q = query(
            collection(db, collectionName),
            where(filter.key, "==", filter.value),
            orderBy("createdAt", "desc"),
            startAfter(pagination.lastVisible),
            limit(newPagination.pageSize)
          );
        } else {
          q = query(
            collection(db, collectionName),
            orderBy("createdAt", "desc"),
            startAfter(pagination.lastVisible),
            limit(newPagination.pageSize)
          );
        }
      } else {
        if (filter) {
          q = query(
            collection(db, collectionName),
            where(filter.key, "==", filter.value),
            limit(newPagination.pageSize)
          );
        } else {
          q = query(
            collection(db, collectionName),
            limit(newPagination.pageSize)
          );
        }
      }
      const snapShot = await getDocs(q);
      if (snapShot.docs.length) {
        const list = await Promise.all(
          snapShot.docs.map(async (doc: any) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          })
        );
        if (list.length) {
          tabulateData.push(list);
          data[collectionName] = tabulateData;
        }

        newPagination = {
          ...newPagination,
          page: newPagination.page + 1,
          lastVisible: snapShot.docs[snapShot.docs.length - 1],
          hasPrevious:
            newPagination.page === -1 ? false : newPagination.page >= 0,
          hasNext: list.length === newPagination.pageSize,
        };
        setPagination({ ...newPagination });
      } else {
        newPagination = {
          ...newPagination,
          page: newPagination.page + 1,
          hasPrevious: newPagination.page >= 0,
          hasNext: false,
        };
        setPagination({ ...newPagination });
      }
    } else {
      newPagination = {
        ...newPagination,
        page: newPagination.page + 1,
        hasPrevious: newPagination.page >= 0,
        hasNext: true,
      };

      setPagination(newPagination);
    }
    if (callbackCollections) {
      await callbackCollections(tabulateData[newPagination?.page] || []);
    }
    setLoading({ ...isLoading, next: false });
    setDataList(tabulateData[newPagination?.page] || []);
  };
  const previous =  async () => {
    var tabulateData = data[collectionName] || [];
    setLoading((prevState) => ({ ...prevState, prev: true }));
    newPagination = {
      ...newPagination,
      page: newPagination.page - 1,
      hasPrevious: pagination.page - 1 > 0,
      hasNext: true,
    };
    setPagination({ ...newPagination });
    if (callbackCollections) {
      await callbackCollections(tabulateData[newPagination?.page] || []);
    }
    setLoading((prevState) => ({ ...prevState, prev: false }));
    setDataList(tabulateData[newPagination.page]);
  };
  const removeData = (id: string) => {
    dataList.forEach((item: any) => {
      if (item.id === id) {
        dataList.splice(dataList.indexOf(item), 1);
      }
    });
    setDataList([...dataList]);
  };

  return (
    <div>
      <Table
        dataSource={dataList}
        columns={columns}
        loading={isLoading?.prev || isLoading?.next || loading}
        pagination={false}
      />
      <StyledDiv>
        <ButtonContainer>
          <Button
            type="primary"
            disabled={!pagination.hasPrevious || isLoading?.prev || loading}
            onClick={() => {
              previous();
            }}
            loading={isLoading.prev}
          >
            {t("Previous")}
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            type="primary"
            disabled={!pagination.hasNext || isLoading?.next || loading}
            onClick={() => {
              next();
            }}
            loading={isLoading?.next}
          >
            {t("Next")}
          </Button>
        </ButtonContainer>
      </StyledDiv>
    </div>
  );
};
export default React.forwardRef(Tabulate);
