import * as AES from "crypto-js/aes"
import { enc } from 'crypto-js';


const encryptMessage = (message: string,key: string) => {
    return  AES.encrypt(message, key).toString();
}

const decryptMessage = (message: string, key: string) => {
        return AES.decrypt(message, key).toString(enc.Utf8);
}

export {
    encryptMessage,
    decryptMessage
}