import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../ContextApi/AuthContext";

const ProtectedRoute = ({ component: RouteComponent, allowedRoles = [], ...rest }: any) => {
  const { loading, role, authenticated } = useContext(AuthContext);

  if (loading) {
    return null;
  }


  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!authenticated) {
          return <Redirect to={{ pathname: "/", state: { prevPath: rest.path } }} />
        }
        if (authenticated && allowedRoles.includes(role)) {
          return <RouteComponent {...routeProps} />;
        }
        return <Redirect to={{ pathname: "/404", state: { prevPath: rest.path } }} />
      }}
    />
  );
};

export default ProtectedRoute;
