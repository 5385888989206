import * as yup from "yup";
import { Job } from "../../constant/interfaces";
import { t } from "i18next";

export const RESIDENT_DATA = [
  {
    label: t("Permanent Residents"),
    value: "permanentResidents",
    type: "noWorkRestrictions",
  },
  {
    label: t("Spouse of japanese"),
    value: "spouseOfJapanese",
    type: "noWorkRestrictions",
  },
  {
    label: t("Long-term Resident"),
    value: "visaLongTermResident",
    type: "noWorkRestrictions",
  },
  {
    label: t("Spouse of Permanent Resident"),
    value: "spouseOfPermanentResident",
    type: "noWorkRestrictions",
  },
  {
    label: t("Studying abroad"),
    value: "studyAbroad",
    type: "someRestriction",
  },
  {
    label: t("Designated Activities"),
    value: "designatedActivities",
    type: "someRestriction",
  },
  {
    label: t("Family Residency"),
    value: "familyResidency",
    type: "someRestriction",
  },
  {
    label: t("Technical Internship"),
    value: "techincalInternship",
    type: "someRestriction",
  },

  { label: t("Diplomacy"), value: "diplomacy", type: "others" },
  { label: t("Official"), value: "official", type: "others" },
  { label: t("Teaching"), value: "teaching", type: "others" },
  { label: t("Press"), value: "press", type: "others" },
  { label: t("Religion"), value: "religion", type: "others" },
  {
    label: t("Management and Administration"),
    value: "managementAndAdminstration",
    type: "others",
  },
  {
    label: t("Instructor"),
    value: "visaInstructor",
    type: "others",
  },
  {
    label: t("Legal and Accounting services"),
    value: "legalAndAccountingServices",
    type: "others",
  },
  {
    label: t("Highly Specialized profession"),
    value: "highlySpecilizedProffesion",
    type: "others",
  },
  { label: t("Research"), value: "research", type: "others" },
  { label: t("Education"), value: "education", type: "others" },
  { label: t("Technical"), value: "technical", type: "others" },
  {
    label: t("Humanities and International Services"),
    value: "humanitiesAndInternationalServices",
    type: "others",
  },
  { label: t("Entertainment"), value: "entertainment", type: "others" },
  { label: t("Technical Skills"), value: "technicalSkills", type: "others" },
  { label: t("Medical care"), value: "medicalCare", type: "others" },
  { label: t("Arts"), value: "arts", type: "others" },
  { label: t("Nursing Care"), value: "nursingCare", type: "others" },
  {
    label: t("Cultural Activities"),
    value: "culturalActivities",
    type: "others",
  },
  {
    label: t("Short-term activities"),
    value: "shortTermActivities",
    type: "others",
  },
  {
    label: t("Trainee"),
    value: "visaTrainee",
    type: "others",
  },
  {
    label: t("Intra-Company Transferee"),
    value: "visaIntraCompanyTransferee",
    type: "others",
  },
  {
    label: t("Temporary Visitor"),
    value: "visaTemporaryVisitor",
    type: "others",
  },
];

export const TAG_SELECTION = [
  {
    label: t("Long-term employees are Welcome"),
    value: "Long-term employees are Welcome",
    type: "welcome",
  },
  {
    label: t("Housewife/ House wife are Welcome"),
    value: "Housewife/ House wife are Welcome",
    type: "welcome",
  },
  {
    label: t("International students are Welcome"),
    value: "International students are Welcome",
    type: "welcome",
  },
  { label: t("Shift System"), value: "Shift System", type: "workingHours" },
  {
    label: t("Weekdays only OK"),
    value: "Weekdays only Ok",
    type: "workingHours",
  },
  { label: t("No Night shift"), value: "No Night Shift", type: "workingHours" },
  {
    label: t("Regular work hours"),
    value: "Regular work hours",
    type: "workingHours",
  },
  {
    label: t("Regular closing hours"),
    value: "Regular closing hours",
    type: "workingHours",
  },
  { label: t("Speedy job offer"), value: "Speedy job offer", type: "others" },
  {
    label: t("No resume required"),
    value: "No resume required",
    type: "others",
  },
  {
    label: t("Hourly wage increase"),
    value: "Hourly wage increase",
    type: "others",
  },
  {
    label: t("Foreign affairs are available"),
    value: "Foreign affairs are available",
    type: "others",
  },
  {
    label: t("Meals and Meals are included"),
    value: "Meals and meals are included",
    type: "others",
  },
  { label: t("Dormitory"), value: "Dormitory", type: "others" },
  {
    label: t("Online interview OK"),
    value: "Online Interview OK",
    type: "others",
  },
  {
    label: t("Piercing/Tattoo OK"),
    value: "Piercing/Tattoo OK",
    type: "others",
  },
  {
    label: t("No experience required"),
    value: "No experience required",
    type: "experience",
  },
  {
    label: t("Experience is preferred"),
    value: "Experience is preferred",
    type: "experience",
  },
];

export const LANGUAGE_QUALIFICATIONS_DETAILS = [
  {
    description: t("Can speak"),
    label: t("Can speak"),
    value: 10,
  },
  {
    description: t("Can speak a little"),
    label: t("Can speak a little"),
    value: 11,
  },
  {
    description: t("Cannot speak"),
    label: t("Cannot speak"),
    value: 12,
  },
];

export const EMPLOYMENT_TYPE = [
  { label: t("Full Time"), value: "fullTime" },
  { label: t("Part Time"), value: "partTime" },
  { label: t("Contract"), value: "contract" },
];

export const INDUSTRIES = [
  { label: t("Cleaning/Bed Making"), value: "cleanOrBedMaking" },
  { label: t("Convenience Store"), value: "convenienceStore" },
  { label: t("Food & Beverage"), value: "restaurants" },
  { label: t("Light Work/Factory"), value: "lightWorkOrFactory" },
];

export const RESIDENT_STATUS = [
  { label: t("No Work Restriction"), value: "noWorkRestrictions" },
  { label: t("Some Restriction"), value: "someRestriction" },
  { label: t("Others"), value: "others" },
];

export const AGE_QUALIFICATION = [
  {
    label: t("High school graduate or older"),
    value: "highSchoolGraduatePlus",
  },
  { label: t("18 years old or older"), value: "18yearPlus" },
];

export const FORM_LEVEL_ERROR_MAP: any = {
  titleEn: "Title (English)",
  jobDescriptionEn: "Job Description (English)",
  title: "Title (Japanese)",
  jobDescription: "Job Description (Japanese)",
};
export const INITIAL_VALUES: Job = {
  storeId: "",
  storeName: "",
  contactPersonsIds: [],
  employmentType: "",
  businessType: "",
  residentStatus: "NoWorkRestriction",
  residentData: [],
  companyId: "",
  companyName: "",
  japaneseLevel: [],
  welcomeTags: [],
  workingHoursTags: [],
  othersTags: [],
  experienceTags: [],
  imageFile: "",
  imagePreviewUrl: "",
  travelExpenses: "no",
  travelExpensesNote: "",
  workingHours: [
    {
      jobType: "",
      jobTypeEn: "",
      minHourlyWage: "",
      salary: "",
      maxHourlyWage: "",
      minHourlyWageEn: "",
      maxHourlyWageEn: "",
      from: "12:00",
      salaryEn: "",
      to: "12:00",
      comment: "",
      commentEn: "",
    },
  ],

  title: "",
  titleEn: "",
  addressJa: "",
  addressEn: "",
  jobDescription: "",
  jobDescriptionEn: "",
};

const withSalaryValidationSchema = yup.object().shape({
  jobType: yup.string().required(t("Job type is required")),
  jobTypeEn: yup.string().required(t("Job type is required")),
  salary: yup.string().required(t("Salary is required")),
  salaryEn: yup.string().required(t("Salary is required")),
  from: yup.string().required(t("Shift start time is required")),
  to: yup.string().required(t("Shift end time is required")),
  comment: yup.string(),
  commentEn: yup.string(),
});

const withMinMaxValidationSchema = yup.object().shape({
  jobType: yup.string().required(t("Job type is required")),
  jobTypeEn: yup.string().required(t("Job type is required")),
  minHourlyWage: yup.string().required(t("Min hour wage is required")),
  maxHourlyWage: yup.string().required(t("Max hour wage is required")),
  minHourlyWageEn: yup
    .string()
    .required(t("Min hour wage is required"))
    .matches(/^(\d+(\.\d+)?)$/, {
      message: t("Enter valid minimum wage"),
    }),
  maxHourlyWageEn: yup
    .string()
    .required(t("Max hour wage is required"))
    .matches(/^(\d+(\.\d+)?)$/, {
      message: t("Enter valid maximum wage"),
    })
    .test({
      name: "minHourlyWageEn",
      exclusive: false,
      params: {},
      message: t("Max hourly wage must be greater than min hourly wage"),
      test: (value, context) => {
        return (
          !context.parent.minHourlyWageEn ||
          context.parent.minHourlyWageEn.length < 1 ||
          Number(value) >= Number(context.parent.minHourlyWageEn)
        );
      },
    }),
  from: yup.string().required(t("Shift start time is required")),
  to: yup.string().required(t("Shift end time is required")),
  comment: yup.string(),
  commentEn: yup.string(),
});

export const ValidationSchema = yup.object().shape({
  titleEn: yup.string().required(t("Job title is required")),
  jobDescription: yup.string().required(t("Job details is required")),
  jobDescriptionEn: yup.string().required(t("Job details is required")),
  storeId: yup.string().required(t("Store ID is required")),
  contactPersonsIds: yup.array().min(1, t("Contact person is required")),
  employmentType: yup.string().required(t("Employment type is required")),
  businessType: yup.string().required(t("Business type is required")),
  residentStatus: yup.string().required(t("Resident status is required")),
  residentData: yup.array().min(1, t("Please select resident checkboxes")),
  title: yup.string().required(t("Job title is required")),
  addressJa: yup.string().required(t("Address is required")),
  addressEn: yup.string().required(t("Address is required")),
  japaneseLevel: yup.array().min(1, t("Japanese language level is required")),
  travelExpenses: yup.string().required(t("Travel expenses is required")),
  workingHours: yup
    .array()
    .when("employmentType", {
      is: (val: string) => val === "fullTime",
      then: (schema) => schema.of(withSalaryValidationSchema),
      otherwise: (schema) => schema.of(withMinMaxValidationSchema),
    })
    .min(1, t("Working hours table is required")),
});

export const MAX_TEXT_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 20000;
export const MAX_TITLE_LENGTH = 200;
