import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { FormComponents, JobFormStyles } from "./JobformStyles";
import {
  Select,
  Row,
  Col,
  Tag,
  Radio,
  Checkbox,
  Space,
  Input,
  Button,
  Typography,
  Form,
  Spin,
  notification,
} from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import CheckableTag from "antd/lib/tag/CheckableTag";
import moment from "moment";
import { db } from "./../../firebase";
import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import {
  catchExceptionCallback,
  generateDraftUrl,
  successNotification,
} from "../../utils";
import { AuthContext } from "../../ContextApi/AuthContext";
import {
  RESIDENT_DATA,
  TAG_SELECTION,
  LANGUAGE_QUALIFICATIONS_DETAILS,
  INITIAL_VALUES,
  ValidationSchema,
  EMPLOYMENT_TYPE,
  INDUSTRIES,
  RESIDENT_STATUS,
} from "./helpers";
import { HourlyWage, IJobFormProps } from "../../constant/interfaces";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BackButton from "./../../assets/image/back-button.svg";
import _ from "lodash";
import { JobListingAdvertisementForm } from "./jobListingFrom";
const { Title } = Typography;
const TITLE_HEADER_LEVEL = 4;
type LanguageMode = "Japanese" | "English";
const CheckboxGroup = Checkbox.Group;

const JobForm: React.FC<IJobFormProps> = ({
  job,
  stores,
  jobId,
  users,
  isNew,
  refetch,
  loading,
}) => {
  const { user } = useContext(AuthContext);
  const router = useHistory();
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] =
    useState<LanguageMode>("Japanese");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [drafSubmitting, setDrafSubmitting] = useState<boolean>(false);
  const [upLoading, setUpLoading] = useState<boolean>(false);
  const expensesNoteTarget =
    activeLanguage === "English"
      ? "travelExpensesNoteEn"
      : "travelExpensesNote";

  useEffect(() => {
    if (
      user?.companyId &&
      job?.companyId &&
      user?.companyId !== job?.companyId
    ) {
      notification.error({ message: "Unauthorized access" });
      router.push("/jobs");
    }
  }, [user, job, router]);

  const storesOptions = stores.map((store) => {
    return { label: store.name, value: store.id };
  });

  const usersOptions = users.map((user) => {
    return { label: user.name, value: user.id };
  });

  const formik = useFormik({
    initialValues: {
      ...INITIAL_VALUES,
      ...job?.draft,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const tagRenderContactPerson = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="#645A82"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ borderRadius: "2em" }}
      >
        {label}
      </Tag>
    );
  };

  const handleSubmit = async (values: any, mode = "published") => {
    const _setSubmitting = (value: boolean) =>
      mode === "published" ? setSubmitting(value) : setDrafSubmitting(value);
    try {
      _setSubmitting(true);
      const now = Timestamp.now();
      const timestamp = {
        updatedAt: now,
      } as any;
      if (isNew) {
        timestamp.createdAt = now;
      }
      const draftData: any = {
        ...values,
        ...timestamp,
        companyId: user?.companyId,
        companyName: user?.companyName,
      };

      let params = {
        draft: draftData,
        ...timestamp,
        isPublished: mode !== "save-draft",
        companyId: user?.companyId,
        companyName: user?.companyName,
      } as any;

      if (mode === "published") {
        params = {
          ...params,
          ...draftData,
          hasPublished: true,
          isPublic: "yes",
        };
      }
      const newJobRef = doc(db, "Jobs", jobId);
      await setDoc(newJobRef, params, { merge: true });
      successNotification(
        mode === "published"
          ? t("Job published successfully")
          : t("Job draft saved successfully")
      );
      refetch();
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      isNew && router.push(`/jobs/${jobId}`);
      _setSubmitting(false);
    }
  };

  const handleUnpublish = async () => {
    setUpLoading(true);
    try {
      const jobRef = doc(db, "Jobs", jobId);
      await updateDoc(jobRef, { isPublic: "no", isPublished: true });
      successNotification(t("Job unpublished successfully"));
      refetch();
    } catch (err) {
      catchExceptionCallback(err);
    } finally {
      isNew && router.push(`/jobs/${jobId}`);
      setUpLoading(false);
    }
  };

  const ResidentCheckForm: React.FC<any> = ({ options }) => {
    const isAllChecked = (): boolean => {
      let isAllChecked: boolean = true;
      options.forEach((value: any) => {
        if (
          !Boolean(
            formik.values.residentData.find(
              (item: string) => item === value.value
            )
          )
        )
          isAllChecked = false;
      });
      return isAllChecked;
    };

    const extractUnchangedValue = (): string[] =>
      formik.values.residentData.filter(
        (value: string) => !options.some((item: any) => item.value === value)
      );

    return (
      <JobFormStyles.SubForm>
        <CheckboxGroup
          options={options}
          value={formik.values.residentData}
          onChange={(value) => {
            let values: string[] = extractUnchangedValue();
            formik.setFieldValue("residentData", [...value, ...values]);
          }}
        />
        <Checkbox
          checked={isAllChecked()}
          onChange={(e: CheckboxChangeEvent) => {
            let values: string[] = extractUnchangedValue();
            e.target.checked
              ? formik.setFieldValue("residentData", [
                  ...values,
                  ...options.map((item: any) => item.value),
                ])
              : formik.setFieldValue("residentData", values);
          }}
        >
          {t("Select All")}
        </Checkbox>
      </JobFormStyles.SubForm>
    );
  };

  const getResidentForm = () => {
    switch (formik.values.residentStatus) {
      case "noWorkRestrictions":
        const noWorkRestrictionOptions = RESIDENT_DATA.filter(
          (item) => item.type === "noWorkRestrictions"
        );
        return <ResidentCheckForm options={noWorkRestrictionOptions} />;

      case "someRestriction":
        const someRestrictionOptions = RESIDENT_DATA.filter(
          (item) => item.type === "someRestriction"
        );
        return <ResidentCheckForm options={someRestrictionOptions} />;

      case "others":
        const otherOptions = RESIDENT_DATA.filter(
          (item) => item.type === "others"
        );
        return <ResidentCheckForm options={otherOptions} />;
    }
  };

  const onHourwageChange = (
    value: any,
    index: number,
    key: string,
    mode: LanguageMode = "English"
  ) => {
    let wageKey = "hourlyWage";
    if (mode === "Japanese") wageKey = "hourlyWageJa";
    let datas = [...formik.values[wageKey]];
    let field: HourlyWage = datas[index];
    if (key === "jobType") {
      field.jobType = value;
    } else if (key === "hourlyWage") {
      field.hourlyWage = value;
    } else if (key === "time_range") {
      let timeFrom = moment(value[0]).format("HH:mm").toString();
      let timeTo = moment(value[1]).format("HH:mm").toString();
      field.from = timeFrom;
      field.to = timeTo;
    } else if (key === "shiftSystem") {
      field.shiftSystem = value;
    }
    datas[index] = field;
    formik.setFieldValue(wageKey, datas);
  };

  const onHourWageremove = (index: number) => {
    let data = [...formik.values.workingHours];
    data.splice(index, 1);
    formik.setFieldValue("workingHours", data);
  };

  const onHourlyWageAdd = () => {
    let key = "workingHours";
    formik.setFieldValue(key, [
      ...formik.values.workingHours,
      INITIAL_VALUES.workingHours[0],
    ]);
  };

  interface ReturnType {
    error: "error" | "";
    message: string;
    forArray: (index: number, subKey: string) => any;
  }

  const haveError = (keyName: string): ReturnType => {
    let error: any = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? "error"
      : "";
    let message: any = error ? formik.errors[keyName] : "";

    const forArray = (
      index: number,
      subKey: string
    ): { error: "error" | ""; message: string } => {
      let error: "error" | "" = "";
      let message = "";
      let errorObj: any = formik.errors[keyName] as any;
      let touchedObj: any = formik.touched[keyName] as any;
      let touched = Boolean(
        Array.isArray(touchedObj) &&
          touchedObj[index] &&
          touchedObj[index][subKey]
      );
      if (touched) {
        error = Boolean(
          Array.isArray(errorObj) &&
            errorObj[index] &&
            errorObj[index][subKey] &&
            touched
        )
          ? "error"
          : "";
      }
      if (error) message = errorObj[index][subKey];
      return {
        error,
        message,
      };
    };
    let errorObj: ReturnType = {
      error,
      message,
      forArray,
    };
    return errorObj;
  };

  const handleStore = (id: string) => {
    const store = stores.find((item) => item.id === id);
    formik.setFieldValue("storeName", store?.name);
    formik.setFieldValue("address", store?.address);
    formik.setFieldValue("townCity", store?.townCity);
    formik.setFieldValue("storeId", id);
  };

  const gotoDraftView = () => {
    window.open(generateDraftUrl(jobId, 1), "_blank", "noopener,noreferrer");
  };

  const handleTags = (checked: boolean, value: string, target: string) => {
    const tagsArr = formik.values[target] || [];
    checked && !tagsArr.includes(value)
      ? tagsArr.push(value)
      : _.remove(tagsArr, (i) => i === value);
    formik.setFieldValue(target, tagsArr);
  };

  return (
    <>
      <JobFormStyles.JobForm className="job-form">
        <Row>
          <Col span={4}>
            <div>
              <img
                src={BackButton}
                style={{ height: "2em", cursor: "pointer" }}
                onClick={() => router.push("/jobs")}
                alt="back button"
              />
            </div>
          </Col>
        </Row>
        <br />
        <Spin spinning={loading}>
          <form onSubmit={formik.handleSubmit}>
            {/* Company/ Store/ Person In Charge Registration */}
            <div className="company-details">
              <Title level={TITLE_HEADER_LEVEL}>
                {t("Store/Person in charge Registration")}
              </Title>
              <JobFormStyles.FormContainer>
                <Row>
                  <Col sm={24} lg={11}>
                    <Form.Item
                      label={t("Store Name")}
                      validateStatus={haveError("storeId").error}
                      help={haveError("storeId").message}
                      required
                    >
                      <Select
                        id="storeId"
                        style={FormComponents.Select}
                        options={storesOptions}
                        value={formik.values.storeId}
                        onChange={handleStore}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Col sm={24} lg={11}>
                  <Form.Item
                    label={t("Contact Person name")}
                    validateStatus={haveError("contactPersonsIds").error}
                    help={haveError("contactPersonsIds").message}
                    required
                  >
                    <Select
                      id="contactPersonName"
                      mode="multiple"
                      tagRender={tagRenderContactPerson}
                      style={FormComponents.Select}
                      options={usersOptions}
                      value={formik.values.contactPersonsIds}
                      onChange={(value) =>
                        formik.setFieldValue("contactPersonsIds", value)
                      }
                    />
                  </Form.Item>
                </Col>
              </JobFormStyles.FormContainer>
            </div>
            {/* Employment type start */}
            <div className="employment-type">
              <Title level={TITLE_HEADER_LEVEL}>
                {t("Employment Type/ Industry Registration")}
              </Title>
              <JobFormStyles.FormContainer>
                <Col sm={24} lg={11}>
                  <Form.Item
                    label={t("Employment Type")}
                    validateStatus={haveError("employmentType").error}
                    help={haveError("employmentType").message}
                    required
                  >
                    <Select
                      style={FormComponents.SelectDisabled}
                      value={formik.values.employmentType}
                      options={EMPLOYMENT_TYPE}
                      onChange={(value) =>
                        formik.setFieldValue("employmentType", value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} lg={11}>
                  <Form.Item
                    label={t("Types of business")}
                    validateStatus={haveError("businessType").error}
                    help={haveError("businessType").message}
                    required
                  >
                    <Select
                      style={FormComponents.Select}
                      value={formik.values.businessType}
                      options={INDUSTRIES}
                      onChange={(value) =>
                        formik.setFieldValue("businessType", value)
                      }
                    />
                  </Form.Item>
                </Col>
              </JobFormStyles.FormContainer>
            </div>
            {/* Application Requirements */}
            <div className="applicant-requirements">
              <Title level={TITLE_HEADER_LEVEL}>
                {t("Application Requirements")}
              </Title>
              <Col span={24}>
                <Form.Item
                  label={t("Resident Status")}
                  validateStatus={
                    haveError("residentStatus").error ||
                    haveError("residentData").error
                  }
                  help={
                    haveError("residentStatus").message ||
                    haveError("residentData").message
                  }
                  required
                >
                  <Radio.Group
                    onChange={(e) =>
                      formik.setFieldValue("residentStatus", e.target.value)
                    }
                    value={formik.values.residentStatus}
                    options={RESIDENT_STATUS}
                  />
                </Form.Item>
                <Col span={24}>{getResidentForm()}</Col>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t("Japanese Level")}
                  validateStatus={haveError("japaneseLevel").error}
                  className="language-qualification"
                  help={haveError("japaneseLevel").message}
                  required
                >
                  <JobFormStyles.SubForm>
                    <CheckboxGroup
                      options={LANGUAGE_QUALIFICATIONS_DETAILS}
                      value={formik.values.japaneseLevel.map((item: any) => {
                        if ([5, 4, 11].includes(item)) return 11;
                        if ([1, 2, 3, 10].includes(item)) return 10;
                        return 12;
                      })}
                      onChange={(value) => {
                        formik.setFieldValue("japaneseLevel", value);
                      }}
                    />
                  </JobFormStyles.SubForm>
                </Form.Item>
              </Col>
            </div>
            {/* Job advertisement */}
            <div className="job-advertisement">
              <Row justify="space-between">
                <Col span={15}>
                  <Title level={TITLE_HEADER_LEVEL}>
                    {t("Job listing advertisement creation")}
                  </Title>
                </Col>
                <Space direction="horizontal">
                  <Button
                    onClick={() => setActiveLanguage("English")}
                    type={activeLanguage === "English" ? "primary" : "default"}
                  >
                    {t("English")}
                  </Button>
                  <Button
                    onClick={() => setActiveLanguage("Japanese")}
                    type={activeLanguage === "Japanese" ? "primary" : "default"}
                  >
                    {t("Japanese")}
                  </Button>
                </Space>
              </Row>
              {/* image create */}
              <JobListingAdvertisementForm
                formik={formik}
                mode={activeLanguage}
                onWorkingHoursAdd={onHourlyWageAdd}
                onWorkingHoursChange={onHourwageChange}
                onWorkingHoursRemove={onHourWageremove}
                haveError={haveError}
              />

              <JobFormStyles.Attributes>
                {t("Tag selection")}
              </JobFormStyles.Attributes>
              <Col span={24}>
                <div style={{ padding: "0 0 1rem 2rem" }}>
                  <div>
                    <JobFormStyles.Attributes>
                      {t("Welcome")}
                    </JobFormStyles.Attributes>
                    {TAG_SELECTION.filter((tag) => tag.type === "welcome").map(
                      (item: any, index) => (
                        <CheckableTag
                          key={index}
                          checked={
                            formik.values.welcomeTags.indexOf(item.value) > -1
                          }
                          onChange={(checked) =>
                            handleTags(checked, item.value, "welcomeTags")
                          }
                        >
                          {item.label}
                        </CheckableTag>
                      )
                    )}
                  </div>
                  <div>
                    <JobFormStyles.Attributes>
                      {t("Working Hours")}
                    </JobFormStyles.Attributes>
                    {TAG_SELECTION.filter(
                      (tag) => tag.type === "workingHours"
                    ).map((item: any, index) => (
                      <CheckableTag
                        key={index}
                        checked={
                          formik.values.workingHoursTags.indexOf(item.value) >
                          -1
                        }
                        onChange={(checked) =>
                          handleTags(checked, item.value, "workingHoursTags")
                        }
                      >
                        {item.label}
                      </CheckableTag>
                    ))}
                  </div>
                  <div>
                    <JobFormStyles.Attributes>
                      {t("Others")}
                    </JobFormStyles.Attributes>
                    {TAG_SELECTION.filter((tag) => tag.type === "others").map(
                      (item: any, index) => (
                        <CheckableTag
                          key={index}
                          checked={
                            formik.values.othersTags.indexOf(item.value) > -1
                          }
                          onChange={(checked) =>
                            handleTags(checked, item.value, "othersTags")
                          }
                        >
                          {item.label}
                        </CheckableTag>
                      )
                    )}
                  </div>
                  <div>
                    <JobFormStyles.Attributes>
                      {t("Experience")}
                    </JobFormStyles.Attributes>
                    {TAG_SELECTION.filter(
                      (tag) => tag.type === "experience"
                    ).map((item: any, index) => (
                      <CheckableTag
                        key={index}
                        checked={
                          formik.values.experienceTags.indexOf(item.value) > -1
                        }
                        onChange={(checked) =>
                          handleTags(checked, item.value, "experienceTags")
                        }
                      >
                        {item.label}
                      </CheckableTag>
                    ))}
                  </div>
                </div>
              </Col>
              <Row>
                <Col>
                  <Form.Item
                    label={t("Travel Expenses")}
                    validateStatus={haveError("travelExpenses").error}
                    help={haveError("travelExpenses").message}
                    required
                  >
                    <Radio.Group
                      onChange={(e) =>
                        formik.setFieldValue("travelExpenses", e.target.value)
                      }
                      value={formik.values.travelExpenses}
                    >
                      <Radio value="yes">{t("Provided")}</Radio>
                      <Radio value="no">{t("Not Provided")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {formik.values.travelExpenses === "yes" && (
                    <Form.Item label={t("Travel expenses Note")}>
                      <Input
                        size="large"
                        value={formik.values[expensesNoteTarget]}
                        onChange={(e) =>
                          formik.setFieldValue(
                            expensesNoteTarget,
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </div>
            {/* Footer */}
            <Row justify="space-between" className="footer">
              <Col>
                {!isNew && (
                  <Button
                    onClick={() => {
                      gotoDraftView();
                    }}
                  >
                    {t("Preview")}
                  </Button>
                )}
              </Col>
              <Space direction="horizontal">
                {Boolean(job?.hasPublished && job?.isPublic === "yes") && (
                  <Button
                    onClick={handleUnpublish}
                    loading={upLoading}
                    danger
                    type="primary"
                  >
                    {t("Unpublish")}
                  </Button>
                )}
                <Button
                  onClick={() => handleSubmit(formik.values, "save-draft")}
                  loading={drafSubmitting}
                >
                  {t("Save draft")}
                </Button>
                <Button loading={submitting} type="primary" htmlType="submit">
                  {t("Publish")}
                </Button>
              </Space>
            </Row>
          </form>
        </Spin>
      </JobFormStyles.JobForm>
    </>
  );
};

export default JobForm;
