import React from "react";
import { CompanyInformationComponent } from "../../components/CompanyInformation";
import DashboardLayout from "../Dashboard";

const Profile: React.FC = (props: any) => {
  return (
    <DashboardLayout>
      <CompanyInformationComponent />
    </DashboardLayout>
  );
};
export default Profile;
