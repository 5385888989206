import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const api = {
  url:
    process.env.REACT_APP_WEBAPP_API
};

const developmentFirebaseConfig = {
  apiKey: "AIzaSyBagS2qAWHMNbAa5WAPNrrZx-qbAWRSdUY",
  authDomain: "quick-job-japan-dev.firebaseapp.com",
  projectId: "quick-job-japan-dev",
  databaseURL:
    "https://quick-job-japan-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  storageBucket: "quick-job-japan-dev.appspot.com",
  messagingSenderId: "1024133865810",
  appId: "1:1024133865810:web:38582c825c952ff8feda8b",
};

const productionFirebaseConfig = {
  apiKey: "AIzaSyC1PRfsNWXoJ6LS4XMPgyXU3566kAq3ptY",
  authDomain: "ready-to-work-d1465.firebaseapp.com",
  databaseURL: "https://ready-to-work-d1465.firebaseio.com",
  projectId: "ready-to-work-d1465",
  storageBucket: "ready-to-work-d1465.appspot.com",
  messagingSenderId: "882070407181",
  appId: "1:882070407181:web:55e017a0cfede34b",
};

let firebaseConfig = developmentFirebaseConfig;
if (
  process.env.NODE_ENV === "production" &&
  window.location.href.indexOf(".quickjobsjapan.com") !== -1
) {
  firebaseConfig = productionFirebaseConfig;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, app, storage, auth, api, firebaseConfig };
