import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Input, Col, Row, Button, Space } from "antd";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import { LoginStyles } from "./loginstyles";
import { AuthContext } from "../../ContextApi/AuthContext";
import { catchExceptionCallback } from "../../utils";
import BrandImage from "./../../assets/image/brand.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import useQuery from "../Hooks/useQuery";
import { useTranslation } from "react-i18next";
import "../../assets/styles/style.css";


export default function Login() {
  const history = useHistory();
  const query = useQuery();
  const loginToken = query.get("token");
  const { authenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const {t}=useTranslation();

  useEffect(() => {

    const handleSignInWithCustomToken = async (token: string) => {
      try {
        setLoading(true);
        await signInWithCustomToken(auth, token);
        sessionStorage.setItem("X-Agent-User", "admin");
        signInWithCustomToken(auth, token);
      } catch (e) {
        history.push("/login");
        catchExceptionCallback(e)
      } finally {
        setLoading(false)
      }
    }
    if (loginToken) handleSignInWithCustomToken(loginToken)
  }, [loginToken, history])

  useEffect(() => {
    if (authenticated) {
      history.push("/jobs");
    }
  }, [authenticated, history]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("Enter valid email")).required(t("Email is Required")),
    password: Yup.string().required(t("Password is required")),
  })

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      submitForm(values);
    }

  });
  const submitForm = async (values: any) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, values.email, values.password)
    } catch (error:any) {
      let message = error
      switch(error?.code){
        case "auth/wrong-password":
          message = t("Password do not match")
          break;
        case "auth/user-not-found":
          message = t("User does not exists.")
          break;
        case "auth/too-many-requests":
          message = t("Too many attempts to login.")
          break;
        case "auth/invalid-email":
          message = t("Enter valid email.")
          break;
        default:
      }
      catchExceptionCallback(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <LoginStyles.FormContainer>
          <LoginStyles.BrandImage>
            <Row>
              <Col span={12} offset={6}>
                <img src={BrandImage} style={{ height: "2em", width: "10em" }} alt="brand imageaß" />
              </Col>
            </Row>
          </LoginStyles.BrandImage>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col span={6} offset={9}>
                <div className="border rounded p-5">
                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                      <br />
                      <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder={t("Enter your Email")}
                        {...formik.getFieldProps("email")}
                      />
                      {formik.errors.email ? <p style={{ "color": "red", "marginBottom": "0.01em" }}>{formik.errors.email}</p> : null}
                    </div>
                    <div>
                      <Input.Password
                        size="large"
                        placeholder={t("Enter your Password")}
                        {...formik.getFieldProps("password")}
                      />
                      {formik.errors.password ? <p style={{ "color": "red", "marginBottom": "0.01em" }}>{formik.errors.password}</p> : null}
                    </div>
                    <LoginStyles.ButtonWrapper
                      className="btn btn-primary"
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ backgroundColor: "#3f51b5" }}
                      >
                        {t('Login')}
                      </Button>
                    </LoginStyles.ButtonWrapper>
                  </Space>
                </div>
              </Col>
            </Row>
          </form>
        </LoginStyles.FormContainer>
      </div>
    </div>
  );
}
