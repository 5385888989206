import React, { useContext, useRef, useState } from "react";
import DashboardLayout from "../Dashboard";
import { Row, Button, Space, Tag, Popconfirm } from "antd";
import { AuthContext } from "../../ContextApi/AuthContext";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../../assets/styles/style.css";
import ModalForm from "./ModalForm";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../services";
import { useTranslation } from "react-i18next";
import Tabulate from "../../utils/tabulate";
import { db } from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  catchExceptionCallback,
  successNotification,
  USER_ROLES,
} from "../../utils";

interface ITableInfo {
  id: string;
  displayName: string;
  email: string;
  phone: string;
  isActive: boolean;
}

const UserManagementPage: React.FC = () => {
  type TabulateType = React.ElementRef<typeof Tabulate>;
  const tabulateRef = useRef<TabulateType>(null);

  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const handleModalOpen = () => setModalStatus(true);
  const handleModalClose = () => setModalStatus(false);
  const { t } = useTranslation();

  const handleDelete = async (uid: string) => {
    try {
      setLoading(true);
      await deleteUser(uid);
      if (user?.companyId) {
        await removeUserIdFromPersonInCharge(uid, user?.companyId);
      }
      tabulateRef.current?.remove(uid);
      successNotification("User removed successfully");
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };
  const removeUserIdFromPersonInCharge = async (
    uid: string,
    companyId: string
  ) => {
    const companyRef = doc(db, "Companies", companyId);
    const docSnap = await getDoc(companyRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const newPersonInCharge = data.personInCharge.filter(
        (userId: any) => userId !== uid
      );
      await setDoc(
        companyRef,
        { personInCharge: newPersonInCharge },
        { merge: true }
      );
    }
  };

  const columns = (handleDelete: any) => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Contact Person"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("Email Address"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      render: (role: string) => {
        return (
          <>{Object.values(USER_ROLES).find((i) => i.value === role)?.label}</>
        );
      },
    },
    {
      title: t("Account Status"),
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) => {
        return (
          <Tag color={isActive ? "green" : "red"}>
            {isActive ? t("Active") : t("Inactive")}
          </Tag>
        );
      },
    },
    {
      title: t("Action"),
      key: "id",
      render: (data: any) => {
        return (
          <>
            {data.role !== USER_ROLES.owner.value && (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => history.push(`/users/${data.id}`)}
                />
                <Popconfirm
                  placement="topRight"
                  title={t("Are you sure you want to remove this User ?")}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  onConfirm={() => handleDelete(data.id)}
                  arrowPointAtCenter
                >
                  <Button icon={<DeleteOutlined />} danger />
                </Popconfirm>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <Row justify="end" style={{ marginBottom: "1rem" }}>
        <Button type="primary" color="primary" onClick={handleModalOpen}>
          追加
        </Button>
      </Row>
      <Tabulate
       key="Admins"
        ref={tabulateRef}
        columns={columns(handleDelete)}
        collectionName="Admins"
        filter={{
          key: "companyId",
          value: user?.companyId ?? "",
        }}
        loading={loading}
      />
      {user?.companyId && (
        <ModalForm
          companyId={user?.companyId ?? ""}
          modalStatus={modalStatus}
          handleModalClose={handleModalClose}
          setReloadPage={setReloadPage}
        />
      )}
    </DashboardLayout>
  );
};

export default UserManagementPage;
