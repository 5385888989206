import React, { useState } from "react";
import ModalInvite from "./ModalInvite";
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal, Spin, Button, Input, Radio } from "antd";
import { catchExceptionCallback, USER_ROLES } from "../../utils";
import "../../assets/styles/style.css"
import { inviteCompanyUser } from "../../services"
import { useTranslation } from "react-i18next";

interface Props {
  companyId: string;
  modalStatus: boolean;
  handleModalClose: any;
  setReloadPage: any;
}

const defaultValues = {
  name: "",
  email: "",
  role: USER_ROLES.public.value
} as any;

const ModalForm: React.FC<Props> = (props) => {
  const { modalStatus, handleModalClose, companyId, setReloadPage } = props;
  const [inviteModalStatus, setInviteModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [inviteResponse, setInviteResponse] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: { ...initialValues, companyId },
    validationSchema: validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  const handleInviteModalClose = () => setInviteModalStatus(false);

  const dispatchInviteModal = () => {
    handleModalClose();
    setInviteModalStatus(true);
    setInitialValues(defaultValues);
  };

  const handleSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await inviteCompanyUser(data);
      setInviteResponse(true);
      dispatchInviteModal();
    } catch (error) {
      setInviteResponse(false)
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
      setReloadPage(true);
    }
  };

  return (
    <React.Fragment>
      <Modal visible={modalStatus} onCancel={handleModalClose}
        footer={
          [
            <Button
              className="modal-btn-style"
              disabled={isLoading}
              onClick={() => { formik.handleSubmit() }}
            >
              {t('Invite')}
            </Button>
          ]
        }
      >
        <div className="modal-root">
          <form onSubmit={formik.handleSubmit}>
            <h1 className="modal-heading">{t('Invite Person-In-Charge')}</h1>
            <div className="modal-input-layout">
              <label className="modal-label">{t('Person Name')}:</label>
              <div className="modal-input-error-container">
                <Input
                  id="name"
                  size="large"
                  className="modal-name-input"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name ? <p className="modal-error-message">{t('Name is Required')}</p> : null}
              </div>
            </div>
            <div className="modal-input-layout">
              <label className="modal-label">{t('Email')}:</label>
              <div className="modal-input-error-container">
                <Input
                  id="email"
                  size="large"
                  className="modal-email-input"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email ? <p className="modal-error-message">{t('Email is Required')}</p> : null}
              </div>
            </div>
            <div className="modal-input-layout">
              <label className="modal-label">{t('Role')}:</label>
              <div className="modal-input-error-container">
                <Radio.Group onChange={formik.handleChange} value={formik.values.role} name="role" id="role">
                  <Radio value={USER_ROLES.public.value}>{t("Public")}</Radio>
                  <Radio value={USER_ROLES.admin.value}>{t("Admin")}</Radio>
                </Radio.Group>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {isLoading && (
        <div className="modal-loader-container">
          <Spin className="modal-circular-progress" size="large" />
        </div>
      )}
      {!modalStatus && inviteModalStatus && (
        <ModalInvite
          success={inviteResponse}
          modalStatus={inviteModalStatus}
          handleModalClose={handleInviteModalClose}
        />
      )}
    </React.Fragment>
  );
};

export default ModalForm;
