import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, message, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { Col, Form, Input, Row, Spin } from "antd";
import { useFormik } from "formik";
import { EDIT_INITIAL_DATA, updateValidationSchema } from "./helper";
import { catchExceptionCallback } from "../../utils";
import { db, auth, storage } from "../../firebase";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Wrapper = styled(Card)`
  width: 100%;
  .hasError p {
    margin-bottom: 0px;
    color: red;
    position: absolute;
  }
`;
const CenterImage = styled.div`
  text-align: center;
`;

export interface User {
  name: string;
  address: string;
  phone: string;
  avatar: string;
  story: string;
}

const ProfileComponent: React.FC = () => {
  const user = auth.currentUser;
  const { t } = useTranslation();
  const [data, setData] = useState<any>(EDIT_INITIAL_DATA);
  const [loading, setLoading] = useState(false);
  const [avtarLoading, setAvtarLoading] = useState(false);

  const formik = useFormik<User>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: updateValidationSchema,
    onSubmit: (_data: object) => {
      const data = { ..._data } as User;
      handleSubmit(data);
    },
  });

  useEffect(() => {
    const getUser = async (userId: string) => {
      try {
        setLoading(true);
        let userRef = doc(db, "Admins", userId);
        const docSnap = await getDoc(userRef);
        const data = docSnap.data() as User;
        if (data) {
          setData(data);
          setImageUrl(data.avatar);
        } else {
          message.error(t("User not found"));
        }
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (user) {
      getUser(user.uid);
    }
  }, [t, user]);

  const handleSubmit = async (data: User) => {
    if (user) {
      try {
        setLoading(true);
        let userRef = doc(db, "Admins", user.uid);
        await setDoc(userRef, data, { merge: true });
        message.success(t("Profile Updated Successfully"));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
  };

  interface ReturnType {
    error: "error" | "";
    message: string;
  }

  const [imageUrl, setImageUrl] = useState<string>();
  const uploadButton = (
    <div>
      {avtarLoading ? (
        <>
          <LoadingOutlined />
          <div style={{ marginTop: 8 }}>Uploading</div>
        </>
      ) : (
        <>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload Avatar</div>
        </>
      )}
    </div>
  );

  const handleChange: UploadProps["onChange"] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      try {
        setAvtarLoading(true);
        const imageFile = info.file.originFileObj as RcFile;
        const storageRef = ref(storage, "Company");
        await uploadBytesResumable(storageRef, imageFile);
        const downloadURL = await getDownloadURL(storageRef);
        let userRef = doc(db, "Admins", user!.uid);
        await setDoc(userRef, { avatar: downloadURL }, { merge: true });

        setImageUrl(downloadURL);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setAvtarLoading(false);
      }
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const haveError = (keyName: keyof User): ReturnType => {
    let error: any = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? "error"
      : "";
    let message: any = error ? formik.errors[keyName] : "";

    let errorObj: ReturnType = {
      error,
      message,
    };
    return errorObj;
  };

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={16}>
            <Spin spinning={loading}>
              <CenterImage>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl && avtarLoading !== true ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </CenterImage>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("Name")}
                    validateStatus={haveError("name").error}
                    help={haveError("name").message}
                    labelCol={{ span: 3 }}
                  >
                    <Input
                      id="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t("Address")}
                    validateStatus={haveError("address").error}
                    labelCol={{ span: 3 }}
                    help={haveError("address").message}
                  >
                    <Input
                      id="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 3 }}
                    label={t("Phone")}
                    validateStatus={haveError("phone").error}
                    help={haveError("phone").message}
                  >
                    <Input
                      id="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 3 }}
                    label={t("Story")}
                    validateStatus={haveError("story").error}
                    help={haveError("story").message}
                  >
                    <Input
                      id="story"
                      value={formik.values.story}
                      onChange={formik.handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ backgroundColor: "#3f51b5" }}
                >
                  {t("Update")}
                </Button>
              </Row>
            </Spin>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
};

export { ProfileComponent };
