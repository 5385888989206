import { Button, Row, Col, Input, Checkbox, Radio, Spin } from "antd";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { updateUserDetail } from "../../services";
import "../../assets/styles/style.css";
import * as yup from "yup";
import {
  catchExceptionCallback,
  successNotification,
  USER_ROLES,
} from "../../utils";
import BackButton from "../../assets/image/back-button.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { AuthContext } from "../../ContextApi/AuthContext";
interface Props {
  id: string;
  user: any;
  isLoading: boolean;
}

const Update: React.FC<Props> = (props) => {
  const { id, user, isLoading } = props;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is Required")),
    phone: yup.string().required(t("Phone number is required")),
    email: yup.string().required(t("Email is Required")),
    role: yup.string().required(t("Role is required")),
    changePassword: yup.boolean().required(),
    newPassword: yup.string().when("changePassword", {
      is: true,
      then: yup.string().required(t("Please enter new password"))
      .min(6, t("Password must be at least 6 characters long"))
    }),
    confirmPassword: yup.string().when("changePassword", {
      is: true,
      then: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], t("Password do not match"))
        .required(t("Please enter confirm password")),
    }),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      let payload = { ...values };
      delete payload.id;
      await updateUserDetail(id, payload);
      successNotification(t("User updated successfully"));
    } catch (error:any) {
      if(error.status === 401) handleSignOut();
      catchExceptionCallback(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSignOut = async () => {
    await signOut(auth);
    setUser(null);
    history.push("/");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      role: USER_ROLES.public.value,
      changePassword: false,
      newPassword: "",
      confirmPassword: "",
      ...user,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col span={4}>
            <div>
              <img
                src={BackButton}
                style={{ height: "2em", cursor: "pointer" }}
                onClick={() => history.push("/users")}
                alt="back button"
              />
            </div>
          </Col>
        </Row>
      </div>
      <br />
      <Spin spinning={isLoading}>
        <form onSubmit={formik.handleSubmit}>
          <Row className="user-update-gridlayout">
            <Col style={{ width: 300 }}>
              <label className="user-update-label">{t("Person Name")}:</label>
            </Col>
            <Col span={8}>
              <Input
                className="user-update-input"
                required
                name="name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && (
                <p className="user-update-error-message">
                  {formik.errors.name}
                </p>
              )}
            </Col>
          </Row>

          <Row className="user-update-gridlayout">
            <Col style={{ width: 300 }}>
              <label className="user-update-label">{t("Phone Number")}:</label>
            </Col>
            <Col span={8}>
              <Input
                className="user-update-input"
                required
                name="phone"
                id="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.phone && (
                <p className="user-update-error-message">
                  {formik.errors.phone}
                </p>
              )}
            </Col>
          </Row>

          <Row className="user-update-gridlayout">
            <Col style={{ width: 300 }}>
              <label className="user-update-label">{t("Email Address")}:</label>
            </Col>
            <Col span={8}>
              <Input
                className="user-update-input"
                required
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && (
                <p className="user-update-error-message">
                  {formik.errors.email}
                </p>
              )}
            </Col>
          </Row>
          <Row className="user-update-gridlayout">
            <Col style={{ width: 300 }}>
              <label className="user-update-label">{t("Role")}:</label>
            </Col>
            <Col span={8}>
              <Radio.Group
                onChange={formik.handleChange}
                value={formik.values.role}
                name="role"
                id="role"
              >
                <Radio value={USER_ROLES.public.value}>{t("Public")}</Radio>
                <Radio value={USER_ROLES.admin.value}>{t("Admin")}</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <div className="user-update-checkbox">
            <Checkbox
              id="changePassword"
              name="changePassword"
              checked={formik.values.changePassword}
              onChange={formik.handleChange}
            />
            &nbsp;&nbsp;
            <label>{t("Change Password")}</label>
          </div>

          {formik.values.changePassword && (
            <>
              <Row className="user-update-gridlayout">
                <Col style={{ width: 300 }}>
                  <label className="user-update-label">
                    {t("New Password")}:
                  </label>
                </Col>
                <Col span={8}>
                  <Input
                    className="user-update-input"
                    name="newPassword"
                    id="newPassword"
                    type={"password"}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.newPassword && (
                    <p className="user-update-error-message">
                      {formik.errors.newPassword}
                    </p>
                  )}
                </Col>
              </Row>

              <Row className="user-update-gridlayout">
                <Col style={{ width: 300 }}>
                  <label className="user-update-label">
                    {t("Confirm Password")}:
                  </label>
                </Col>
                <Col span={8}>
                  <Input
                    className="user-update-input"
                    name="confirmPassword"
                    id="confirmPassword"
                    type={"password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.confirmPassword && (
                    <p className="user-update-error-message">
                      {formik.errors.confirmPassword}
                    </p>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className="user-update-update-btn"
            disabled={isSubmitting}
          >
            {t("Update")}
          </Button>
        </form>
      </Spin>
    </React.Fragment>
  );
};

export default Update;
