import React, { useContext, useEffect, useState } from "react";
import { Input, Col, Row, Button, Typography, Space, Spin, Result } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../ContextApi/AuthContext";
import { catchExceptionCallback } from "../../utils";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import { decryptMessage } from "../../utils/encrypt-decrypt";
import { INVITE_ENCRYPTION_KEY } from "../../constant/constant";
import { verifyInvitation, acceptInvitation } from "../../services";
import * as yup from "yup";
import styled from "styled-components";


const { Title, Paragraph } = Typography;

const ErrorStyled = styled.span`
  font-size: 12px;
  color: 'red';
  margin-top: 2px;
  margin-left: 2px;
`;


const Invitation: React.FC = (props: any) => {

    const { match } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const { authenticated } = useContext(AuthContext);
    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (authenticated) {
            history.push("/jobs");
        }
    }, [authenticated, history]);

    useEffect(() => {

        const geInvitetUser = async (encryptedMessage: string) => {
            try {
                setLoading(true);
                var decodedMessage = Buffer.from(encryptedMessage, 'base64')
                const decryptedMessage = decryptMessage(decodedMessage.toString(), INVITE_ENCRYPTION_KEY)
                const parsedUser = JSON.parse(decryptedMessage)
                await verifyInvitation(parsedUser.userID);
                setUser(parsedUser)
            } catch (e) {
                catchExceptionCallback(e)
            } finally {
                setLoading(false);
            }
        }
        geInvitetUser(match.params.invitationId)
    }, [match.params.invitationId])



    const handleSubmit = async (values: any) => {
        try {
            setProcessing(true);
            await acceptInvitation(user.userID, { phone: values.phone, password: values.password });
            signInWithEmailAndPassword(auth, user.email, values.password);
        } catch (error) {
            catchExceptionCallback(error);
        } finally {
            setProcessing(false)
        }
    }

    const validationSchema = yup.object().shape({
        phone: yup.string().required(t('Required')),
        password: yup.string().required(t('Required')),
        confirmPassword: yup.string().required(t('Required')).oneOf([yup.ref('password'), null], t("Password do not match"))
    });


    const formik = useFormik({
        initialValues: { phone: "", password: "", confirmPassword: "" },
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    if (loading) return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin size="large"></Spin>
        </div>
    )

    if (!user) return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Result
                status="error"
                title="Page Not Accessible"
                subTitle="Sorry this page is not accessible. Please contact administration."
            >
            </Result>
        </div>
    )

    return (
        <div style={{ backgroundColor: '#F5F5F5', height: '100vh' }}>
            {processing &&
                <div style={{ position: "fixed", height: "100vh", width: "100vw", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.25)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 9999 }}>
                    <Spin size="large"></Spin>
                </div>
            }
            <form onSubmit={formik.handleSubmit} >
                <Row >
                    <Col span={9} offset={9} style={{ margin: '6rem auto' }}>
                        <Row justify="start" align="middle" gutter={[48, 0]}>
                            <Col>
                                <Title level={4}>{t('Person In Charge Information')}</Title>
                            </Col>
                            <Col>
                                <Paragraph italic={true} style={{
                                    margin: 0, color: '#787878',
                                }}>{t('All fields are required')}</Paragraph>
                            </Col>
                        </Row>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Row gutter={[48, 0]}>
                                <Col span={8}>{t('Person Name')}</Col>
                                <Col>{user.name}</Col>
                            </Row>

                            <Row gutter={[48, 0]}>
                                <Col span={8}>{t('Email Address')}</Col>
                                <Col>
                                    <Paragraph italic={true} style={{
                                    }}>{user.email}</Paragraph></Col>
                            </Row>
                            <Row align="middle" gutter={[48, 0]}>
                                <Col span={8}>{t('Phone Number')}</Col>
                                <Col span={16}>
                                    <Input
                                        id="phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        style={{ borderRadius: '10px' }} />
                                    {formik.errors.phone && <ErrorStyled>{formik.errors.phone}</ErrorStyled>}
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[48, 0]}>
                                <Col span={8}>{t('Password')}</Col>
                                <Col span={16}>
                                    <Input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        style={{ borderRadius: '10px' }}
                                    />
                                    {formik.errors.password && <ErrorStyled>{formik.errors.password}</ErrorStyled>}
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[48, 0]} >
                                <Col span={8}>{t('Confirm Password')}</Col>
                                <Col span={16}>
                                    <Input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword}
                                        style={{ borderRadius: '10px' }}
                                    />
                                    {formik.errors.confirmPassword && <ErrorStyled>{formik.errors.confirmPassword}</ErrorStyled>}
                                </Col>
                            </Row>
                            <Row justify="center" >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ color: 'white', borderRadius: '10px', marginTop: '1rem', backgroundColor: '#645A82' }}
                                    disabled={processing}
                                >
                                    {t('Login')}
                                </Button>
                            </Row>
                        </Space>
                    </Col>

                </Row>
            </form>
        </div>
    );
};
export default Invitation;
