import {
  Button,
  Col,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import styled from "styled-components";
import { catchExceptionCallback, successNotification } from "../../utils";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useFormik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import BackButton from "./../../assets/image/back-button.svg";
import { AuthContext } from "../../ContextApi/AuthContext";
import { INDUSTRIES, RESIDENT_DATA } from "../../components/Job/helpers";
import { t as _t } from "i18next";

const { Title, Paragraph } = Typography;

const MarginCol = styled(Col)`
  margin: 1rem auto;
`;
const FlexSpace = styled(Space)`
  display: flex;
`;

type ICustomProps = {
  title: string;
  value: string;
};

const ApplicationForm = styled.form`
  margin-top: 10px;
  .select-box {
    min-width: 20rem;
  }
`;

interface IFormProps {
  id: string;
}

export const APPLICATION_STATUS = [
  {
    label: _t("Unsupported"),
    value: "unsupported",
    color: "red",
  },
  {
    label: _t("Already Responded (by e-mail)"),
    value: "already-responded-by-email",
    color: "yellow",
  },
  {
    label: _t("Already Responded (by phone call)"),
    value: "already-responded-by-email",
    color: "yellow",
  },
  {
    label: _t("Interview adjustment completed"),
    value: "interview-adjustment-completed",
    color: "yellow",
  },
  {
    label: _t("Passed 1st interview"),
    value: "passed-first-interview",
    color: "green",
  },
  {
    label: _t("Passed 2nd interview"),
    value: "passed-second-interview",
    color: "green",
  },
  {
    label: _t("Passed 3rd interview"),
    value: "passed-third-interview",
    color: "green",
  },
  {
    label: _t("Informal offer/accepted"),
    value: "informal-offer-accepted",
    color: "green",
  },
  { label: _t("Rejected"), value: "rejected", color: "red" },
  { label: _t("No contact/no reply"), value: "no-contact", color: "red" },
];

const Detail: React.FC<IFormProps> = (props: IFormProps) => {
  const { id } = props;
  const { user } = useContext(AuthContext);
  const router = useHistory();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<any>(null);
  const [job, setJob] = useState<any>(null);
  const [applicant, setApplicant] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const getApplications = async () => {
      try {
        setLoading(true);
        const applicationSnapshot = await getDoc(doc(db, "Applications", id));
        const application: any = applicationSnapshot.data();
        const jobSnapshot = await getDoc(doc(db, "Jobs", application.jobId));
        const applicantSnapshot = await getDoc(
          doc(db, "Users", application.uid)
        );
        setApplication(application);
        setJob(jobSnapshot.data());
        setApplicant(applicantSnapshot.data());
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };

    getApplications();
  }, [id]);

  useEffect(() => {
    if (
      application &&
      application?.companyId &&
      user?.companyId &&
      application.companyId !== user?.companyId
    ) {
      notification.error({ message: "Unauthorized access" });
      router.push("/applications");
    }
  }, [id, application]);

  const formik = useFormik({
    initialValues: {
      status: application?.status || "",
      memo: application?.memo || "",
    },
    enableReinitialize: true,
    onSubmit: (data: unknown) => handleSubmit(data),
  });
  const handleSubmit = async (_data: any) => {
    let data = { ..._data };
    const applicationID = doc(db, "Applications", id);
    setButtonLoading(true);
    updateDoc(applicationID, data)
      .then((res) => {
        successNotification(t("Application updated successfully"));
        setButtonLoading(false);
      })
      .catch((error) => {
        catchExceptionCallback(error);
        setButtonLoading(false);
      });
  };

  const CustomRow = (props: ICustomProps) => {
    return (
      <Row gutter={[48, 0]}>
        <Col span={8}>{props.title}</Col>
        <Col span={16}>
          <Paragraph italic={true}>{t(props.value)}</Paragraph>
        </Col>
      </Row>
    );
  };

  const returnLangLevel = (num: string) => {
    if (["N4", "N5", "N11"].includes(num)) return t("N11");
    else if (["N1", "N2", "N3", "N10"].includes(num)) return t("N10");
    else return t("N12");
  };

  return (
    <>
      <Spin spinning={loading || buttonLoading}>
        <Row>
          <Col span={4}>
            <div>
              <img
                src={BackButton}
                style={{ height: "2em", cursor: "pointer" }}
                onClick={() => history.push("/applications")}
                alt="back button"
              />
            </div>
          </Col>
        </Row>
        <br />
        <Title level={4}>{t("Applicant Management")}</Title>
        {application && (
          <Row>
            <MarginCol span={24} offset={3}>
              <FlexSpace direction="vertical" size="middle">
                <CustomRow title={t("Application Id")} value={id} />
                <CustomRow
                  title={t("Applied Date")}
                  value={application.createdAt.toDate().toString()}
                />
              </FlexSpace>
              {job && (
                <>
                  <MarginCol>
                    <Title level={4}>{t("Job Information")}</Title>
                  </MarginCol>
                  <FlexSpace direction="vertical" size="middle">
                    <CustomRow
                      title={t("Company Name")}
                      value={job.companyName}
                    />
                    <CustomRow title={t("Job Id")} value={application.jobId} />
                    <CustomRow title={t("Job Title")} value={job.title} />
                    <Row gutter={[48, 0]}>
                      <Col span={8}>{t("Job Description")}</Col>
                      <Col span={16}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(job.jobDescription),
                          }}
                        />
                      </Col>
                    </Row>
                    <CustomRow
                      title={t("Types of business")}
                      value={
                        INDUSTRIES.find(
                          (item) => item.value === job.businessType
                        )?.label || ""
                      }
                    />
                    <CustomRow
                      title={t("Transportation allowance")}
                      value={job.travelExpenses}
                    />
                  </FlexSpace>
                </>
              )}
              {applicant && (
                <>
                  <MarginCol>
                    <Title level={4}>{t("Applicant Information")}</Title>
                  </MarginCol>
                  <FlexSpace direction="vertical" size="middle">
                    <CustomRow title={t("Name")} value={applicant.name} />
                    <CustomRow title={t("Email")} value={applicant.email} />
                    <CustomRow
                      title={t("Gender")}
                      value={t(applicant.gender) || "-"}
                    />
                    <CustomRow
                      title={t("Telephone number")}
                      value={applicant.tel}
                    />
                    <CustomRow
                      title={t("Nationality")}
                      value={applicant.nationality || "-"}
                    />
                    <CustomRow
                      title={t("Are you currently living in japan?")}
                      value={applicant.inJapan || "-"}
                    />
                    <CustomRow
                      title={t("Status of residence")}
                      value={
                        RESIDENT_DATA.find(
                          (item) =>
                            (applicant.statusOfResidence2 === ""
                              ? applicant.statusOfResidence1
                              : applicant.statusOfResidence2) === item.value
                        )?.label ||
                        (applicant.statusOfResidence2 === ""
                          ? applicant.statusOfResidence1
                          : applicant.statusOfResidence2)
                      }
                    />
                    <CustomRow
                      title={t("Japanese level")}
                      value={returnLangLevel(applicant.japaneseLevel)}
                    />
                    <CustomRow
                      title={t("Result contact method")}
                      value={
                        applicant.contactMethod?.email ? t("Email") : t("Phone")
                      }
                    />
                  </FlexSpace>
                </>
              )}

              <ApplicationForm onSubmit={formik.handleSubmit}>
                <FlexSpace direction="vertical" size="middle">
                  <Row gutter={[48, 0]}>
                    <Col span={8}>{t("Status")}</Col>
                    <Col>
                      <Select
                        className="select-box"
                        value={formik.values.status}
                        onChange={(value) =>
                          formik.setFieldValue("status", value)
                        }
                      >
                        {APPLICATION_STATUS.map((item) => (
                          <Select.Option value={item.value} key={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[48, 0]}>
                    <Col span={8}>{t("Memo")}</Col>
                    <Col>
                      <TextArea
                        value={formik.values.memo}
                        name="memo"
                        className="select-box"
                        onChange={formik.handleChange}
                        rows={2}
                        cols={30}
                      />
                    </Col>
                  </Row>
                </FlexSpace>
                <FlexSpace>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={buttonLoading}
                  >
                    {t("Update")}
                  </Button>
                </FlexSpace>
              </ApplicationForm>
            </MarginCol>
          </Row>
        )}
      </Spin>
    </>
  );
};
export default Detail;
