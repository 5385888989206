import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { db } from "../../../../firebase";
import * as yup from "yup";
import BackButton from "./../../../../assets/image/back-button.svg";
import { AuthContext } from "../../../../ContextApi/AuthContext";

import {
  buildResultsDocs,
  catchExceptionCallback,
  prefectureList,
  successNotification,
} from "../../../../utils";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";

interface IFormProps {
  store: any;
  id: string;
}

const { Option } = Select;
const StoreForm: React.FC<IFormProps> = (props: IFormProps) => {
  const { store, id } = props;
  const { user } = useContext(AuthContext);
  const [personInCharge, setPersonInCharge] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPersonInCharge = async () => {
      try {
        setIsLoading(true);
        const q = query(
          collection(db, "Admins"),
          where("companyId", "==", user?.companyId)
        );
        const adminSnapshot = await getDocs(q);
        const adminList = await buildResultsDocs(adminSnapshot);
        setPersonInCharge(adminList);
      } catch (err: any) {
        catchExceptionCallback(err?.message);
      } finally {
        setIsLoading(false);
      }
    };

    getPersonInCharge();
  }, [user]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is Required")),
    nameEn: yup.string().required(t("Name is Required")),
    address: yup.string().required(t("Prefecture is required")),
    townCity: yup.string().required(t("Town/City is required")),
    personId: yup.string().required(t("Contact person is required")),
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const now = Timestamp.now();
      const params = { ...values, updatedAt: now };
      if (!store) params.createdAt = now;
      let storeRef = doc(db, "Stores", id);
      await setDoc(storeRef, params, { merge: true });
      successNotification(
        store ? t("Store updated successfully") : t("Store Added Successfully")
      );
      history.push(`/stores`);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      companyId: user?.companyId,
      personId: "",
      townCity: "",
      ...store,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data: unknown) => handleSubmit(data),
  });

  const haveError = (keyName: any): any => {
    let error: any = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? "error"
      : "";
    let message: any = error ? formik.errors[keyName] : "";
    let errorObj: any = {
      error,
      message,
    };
    return errorObj;
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <div>
          <Row>
            <Col span={4}>
              <div>
                <img
                  src={BackButton}
                  style={{ height: "2em", cursor: "pointer" }}
                  onClick={() => history.push("/stores")}
                  alt="back button"
                />
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <Row>
          <Col span={12}>
            <form onSubmit={formik.handleSubmit}>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label={t("Store Name")}
                  validateStatus={haveError("name").error}
                  help={haveError("name").message}
                >
                  <Input
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label={t("Store Name (English)")}
                  validateStatus={haveError("nameEn").error}
                  help={haveError("nameEn").message}
                >
                  <Input
                    id="nameEn"
                    value={formik.values.nameEn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label={t("Prefecture")}
                  validateStatus={haveError("address").error}
                  help={haveError("address").message}
                >
                  <Select
                    id="prefecture"
                    value={formik.values.address}
                    onChange={(value: string) =>
                      formik.setFieldValue("address", value)
                    }
                    onBlur={formik.handleBlur}
                  >
                    {prefectureList.map((item: any, index: number) => (
                      <Option value={item} key={index}>
                        {t(item)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label={t("Town city (remaining address)")}
                  validateStatus={haveError("townCity").error}
                  help={haveError("townCity").message}
                >
                  <Input
                    id="townCity"
                    value={formik.values.townCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  label={t("Contact Person")}
                  validateStatus={haveError("personId").error}
                  help={haveError("personId").message}
                >
                  <Select
                    id="personId"
                    value={formik.values.personId}
                    onChange={(value: string) =>
                      formik.setFieldValue("personId", value)
                    }
                    onBlur={formik.handleBlur}
                  >
                    {personInCharge.map((item: any) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Row justify="center">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {store ? t("Update") : t("Add")}
                </Button>
              </Row>
            </form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export { StoreForm };
