import {api,firebaseConfig} from "../firebase"
import { API } from "../api"
import Axios from "axios";
import {encryptMessage} from "../utils/encrypt-decrypt"
import { INVITE_ENCRYPTION_KEY } from "../constant/constant";

const USER_URL = `${api.url}/user`;

export const getUser = async (uid :string) =>{
   const data = await API.get(`/user/${uid}`);
   return data;
}

export const acceptInvitation = async (uid:string, payload: object) => {
  const secret = Buffer.from(encryptMessage(JSON.stringify(payload),INVITE_ENCRYPTION_KEY)).toString('base64')
  const data = {...payload,secret}
  await API.post(`${api.url}/invite-user/accept/${uid}`, data);
}

export const updateUserDetail = async (uid: string, payload: unknown) =>
  API.post(`${USER_URL}/update-detail/${uid}`, payload);

export const identitytoolkitSignInWithPassword = async (payload: unknown) => Axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseConfig.apiKey}`,
    payload
  );

  export const deleteUser = async (uid:string) => API.delete(`${USER_URL}/delete/${uid}`)

  export const verifyInvitation = async (uid:string) => API.get(`${api.url}/invite-user/verify/${uid}`);