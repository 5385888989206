import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useFormik } from "formik";
import { EDIT_INITIAL_DATA, updateValidationSchema } from "./helper";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Company, User } from "../../constant/interface";
import {
  buildResultsDocs,
  catchExceptionCallback,
  successNotification,
} from "../../utils";
import { AuthContext } from "../../ContextApi/AuthContext";

const { Option } = Select;
const CompanyInformationComponent: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [data, setData] = useState<any>(EDIT_INITIAL_DATA);
  const [loading, setSubmitting] = useState(false);
  const [personInCharge, setPersonInCharge] = useState<User[]>([]);
  const [isFetchingPIC, setFetchingPIC] = useState(true);

  const formik = useFormik<Company>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: updateValidationSchema,
    onSubmit: (_data: object) => {
      const data = { ..._data } as Company;
      handleSubmit(data);
    },
  });

  const handleSubmit = async (data: Company) => {
    setSubmitting(true);
    try {
      if (!user?.companyId) return false;
      const companyRef = doc(db, "Companies", user?.companyId);
      await setDoc(companyRef, data, { merge: true });
      successNotification(t("Company updated successfully"));
    } catch (err: any) {
      catchExceptionCallback(err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const getPersonInCharge = async () => {
      try {
        if (user?.companyId) {
          const companyRef = doc(db, "Companies", user?.companyId);
          const docSnap = await getDoc(companyRef);
          if (docSnap.exists()) setData(docSnap.data());
        }
        const q = query(
          collection(db, "Admins"),
          where("companyId", "==", user?.companyId)
        );
        const adminSnapshot = await getDocs(q);
        const adminList: User[] = await buildResultsDocs(adminSnapshot);
        setPersonInCharge(adminList);
      } catch (err: any) {
        catchExceptionCallback(err?.message);
      } finally {
        setFetchingPIC(false);
      }
    };

    getPersonInCharge();
  }, [user]);

  interface ReturnType {
    error: "error" | "";
    message: string;
    forArray: (index: number, subKey: string) => any;
  }

  const haveError = (keyName: keyof Company): ReturnType => {
    let error: any = Boolean(formik.errors[keyName] && formik.touched[keyName])
      ? "error"
      : "";
    let message: any = error ? formik.errors[keyName] : "";

    const forArray = (
      index: number,
      subKey: string
    ): { error: "error" | ""; message: string } => {
      let error: "error" | "" = "";
      let message = "";
      let errorObj: any = formik.errors[keyName] as any;
      let touchedObj: any = formik.touched[keyName] as any;
      let touched = Boolean(
        Array.isArray(touchedObj) &&
          touchedObj[index] &&
          touchedObj[index][subKey]
      );
      if (touched) {
        error = Boolean(
          Array.isArray(errorObj) &&
            errorObj[index] &&
            errorObj[index][subKey] &&
            touched
        )
          ? "error"
          : "";
      }
      if (error) message = errorObj[index][subKey];
      return {
        error,
        message,
      };
    };
    let errorObj: ReturnType = {
      error,
      message,
      forArray,
    };
    return errorObj;
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Spin spinning={loading || isFetchingPIC}>
          <Row>
            <Col style={{ width: 200 }}>
              <label>{t("Company Name")}:</label>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={haveError("companyName").error}
                help={haveError("companyName").message}
              >
                <Input
                  required
                  name="companyName"
                  id="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: 200 }}>
              <label>{t("Company Name (English)")}:</label>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={haveError("companyNameEn").error}
                help={haveError("companyNameEn").message}
              >
                <Input
                  required
                  name="companyNameEn"
                  id="companyNameEn"
                  value={formik.values.companyNameEn}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: 200 }}>
              <label>{t("Address")}:</label>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={haveError("address").error}
                help={haveError("address").message}
              >
                <Input
                  required
                  name="address"
                  id="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: 200 }}>
              <label>{t("Website URL")}:</label>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={haveError("websiteURL").error}
                help={haveError("websiteURL").message}
              >
                <Input
                  required
                  name="websiteURL"
                  id="websiteURL"
                  value={formik.values.websiteURL}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: 200 }}>
              <label>{t("Administrator")}:</label>
            </Col>
            <Col span={12}>
              <Form.Item
                validateStatus={haveError("personInCharge").error}
                help={haveError("personInCharge").message}
              >
                <Select
                  mode="multiple"
                  id="personInCharge"
                  value={formik.values.personInCharge}
                  onChange={(e) => {
                    formik.setFieldValue("personInCharge", e);
                  }}
                >
                  {personInCharge.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col push={3}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t("Update")}
            </Button>
          </Col>
        </Spin>
      </form>
    </div>
  );
};

export { CompanyInformationComponent };
