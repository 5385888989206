import axios from "axios";
import { api, auth } from "./firebase";
/**
 * axios instance
 */

axios.defaults.responseType = "json";

const API = axios.create({
  baseURL: api.url,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = await  auth.currentUser?.getIdToken(true);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export { API };
