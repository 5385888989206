import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/login";
import { AuthProvider } from "./ContextApi/AuthContext";
import ProtectedRoute from "./Routes/ProtectedRoute";
import UserList from "./pages/users/List";
import UserUpdate from "./pages/users/Update";
import JobList from "./pages/jobs/List";
import { DashBoardProvider } from "./ContextApi/DashBoardContext";
import CompanyInformation from "./pages/company";
import InvitationPage from "./pages/users/Invitation";
import Applications from "./pages/applications/List";
import CreateUpdateJob from "./pages/jobs/createUpdate";
import Stores from "./pages/stores/List";
import { CreateUpdateStore } from "./pages/stores/CreateUpdateStore";
import ChangePassword from "./pages/change-password";
import MyProfile from "./pages/UserProfile/profile";
import PageNotFound from "./pages/404";
import { ROLES } from "./utils";
import { Application } from "./pages/applications/application";

function App() {
  return (
    <AuthProvider>
      <DashBoardProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route
              exact
              path="/users/invitation/:invitationId"
              component={InvitationPage}
            />
            <Switch>
              <ProtectedRoute
                exact
                path="/users/:id"
                component={UserUpdate}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER]}
              />

              <ProtectedRoute
                exact
                path="/users"
                component={UserList}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER]}
              />

              <ProtectedRoute
                exact
                path="/company-information"
                component={CompanyInformation}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER]}
              />

              <ProtectedRoute
                exact
                path="/my-profile"
                component={MyProfile}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path="/change-password"
                component={ChangePassword}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path="/jobs"
                component={JobList}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path={"/jobs/:id"}
                component={CreateUpdateJob}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path={"/stores/:id"}
                component={CreateUpdateStore}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path="/applications"
                component={Applications}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path="/stores"
                component={Stores}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <ProtectedRoute
                exact
                path="/applications/:id"
                component={Application}
                allowedRoles={[ROLES.ADMIN, ROLES.OWNER, ROLES.PUBLIC]}
              />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </Switch>
        </BrowserRouter>
      </DashBoardProvider>
    </AuthProvider>
  );
}

export default App;
