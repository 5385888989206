import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StoreForm } from "../../components/Store/List/Form";
import DashboardLayout from "../Dashboard";
import { db } from "../../firebase";
import { catchExceptionCallback } from "../../utils";
import { Spin } from "antd";

interface CreateUpdateStoreProps extends RouteComponentProps<{ id: string }> {}

const CreateUpdateStore: React.FC<CreateUpdateStoreProps> = (
  props: CreateUpdateStoreProps
) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [store, setStore] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const snap = await getDoc(doc(db, "Stores", id));
        setStore(snap.data());
      } catch (error) {
        catchExceptionCallback(error);
      }finally{
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <DashboardLayout>
      <Spin spinning={loading}>
        <StoreForm store={store} id={id} />
      </Spin>
    </DashboardLayout>
  );
};

export { CreateUpdateStore };
