import * as yup from "yup";
import  {t} from "i18next";

export const TEXT_FIELD_VARIANT = "outlined";
export const INITIAL_DATA = {
  name: "",
  address: "",
  phone: "",
  story: "",
};
export const EDIT_INITIAL_DATA = {
  ...INITIAL_DATA,
  personInCharge: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const commonValidationSchema = {
  name: yup.string().required(t("Name is Required")),
  address: yup.string().required(t("Address is Required")),
  phone: yup
    .string()
    .matches(/^[0-9]{10,13}$/, t("Phone number is invalid"))
    .required(t("Phone number is required")),
  story: yup.string().required(t("Story is required")),
};
export const updateValidationSchema = yup.object().shape({
  ...commonValidationSchema,
  avatar: yup.string().required(t("Avatar is required")),
});
