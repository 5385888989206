import React, { useContext } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import JobForm from "../../components/Job/Jobform";
import { db } from "../../firebase";
import { buildResultsDocs, catchExceptionCallback } from "../../utils";
import DashboardLayout from "../Dashboard";
import { AuthContext } from "../../ContextApi/AuthContext";

interface ICreateUpdateProps extends RouteComponentProps<{ id: string }> {}

const getUrlPramas = (hash: string) => {
  return hash.slice(hash.indexOf("#") + 1);
};

const CreateUpdate: React.FC<ICreateUpdateProps> = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const cloneId = getUrlPramas(props.location.hash);

  const { user } = useContext(AuthContext);
  const [job, setJob] = useState<any>(null);
  const [stores, setStores] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getJob = async (jobId: string) => {
    try {
      setLoading(true);
      const snap = await getDoc(doc(db, "Jobs", jobId));
      const storeSnap = await getDocs(
        query(
          collection(db, "Stores"),
          where("companyId", "==", user?.companyId)
        )
      );
      const userSnap = await getDocs(
        query(
          collection(db, "Admins"),
          where("companyId", "==", user?.companyId)
        )
      );
      const job = cloneId ? { draft: snap.data()?.draft } : snap.data();
      setJob(job);
      setStores(buildResultsDocs(storeSnap));
      setUsers(buildResultsDocs(userSnap));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    cloneId ? getJob(cloneId) : id && getJob(id);
  }, [id, user, cloneId]);

  const refetch = () => getJob(id);
  return (
    <DashboardLayout>
      <JobForm
        loading={loading}
        job={job}
        stores={stores}
        jobId={id}
        users={users}
        refetch={refetch}
        isNew={Boolean(cloneId || !job)}
      />
    </DashboardLayout>
  );
};

export default CreateUpdate;
