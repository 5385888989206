import React, { useEffect, useState } from "react";
import DashboardLayout from "../Dashboard";
import UserUpdateForm from "./UserUpdateForm";
import "../../assets/styles/style.css"
import { RouteComponentProps } from "react-router-dom";
import { catchExceptionCallback } from "../../utils";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

interface IProps
  extends RouteComponentProps<{ id: string; }> { }

const UserUpdatePage: React.FC<IProps> = (props) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    const getCompanyUser = async () => {
      try {
        const snap = await getDoc(doc(db, "Admins", id))
        setUser(snap.data());
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setIsLoading(false);
      }
    };
    id && getCompanyUser();
  }, [id]);

  return (
    <DashboardLayout>
      <UserUpdateForm id={id} user={user} isLoading={isLoading} />
    </DashboardLayout>
  )
}

export default UserUpdatePage