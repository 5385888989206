import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Row, Space, Tag, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { db } from "../../../firebase";
import { AuthContext } from "../../../ContextApi/AuthContext";
import { useTranslation } from "react-i18next";
import Tabulate from "../../../utils/tabulate";
import { collection, deleteDoc, doc, getDoc } from "firebase/firestore";
import { buildDataFromDocs, catchExceptionCallback } from "../../../utils";

const StoreList: React.FC = () => {
  type TabulateType = React.ElementRef<typeof Tabulate>;
  const tabulateRef = useRef<TabulateType>(null);
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>();
  const { t } = useTranslation();

  const setStore = (id: any) => {
    setSelectedStore(id);
    setDeleteModal(true);
  };

  const deleteStore = async (key: string) => {
    setDeleteModal(false);
    try {
      setLoading(true);
      await await deleteDoc(doc(db, "Stores", key));
      tabulateRef.current?.remove(key);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCallback = async (list: any) => {
    const promiseUsers: Promise<any>[] = [];
    list.forEach((store: any) => {
      store.personId &&
      promiseUsers.push(getDoc(doc(db, "Admins", store?.personId)));
    });
    const usersSnapshots = await Promise.all(promiseUsers);
    var usersList = buildDataFromDocs(usersSnapshots);
    Object.keys(usersList).map((user: any) => (users[user.id] = user));
    setUsers(usersList);
  }

  const columns = (history: any, users: any, t: any) => [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Store Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      render: (address: string) => (
        t(address)
      )
    },
    {
      title: t("Person In Charge"),
      dataIndex: "personId",
      key: "personId",
      render: (personId: string) => (
        <div>
          <Tag color="blue">{users[personId]?.name} </Tag>
        </div>
      ),
    },
    {
      title: t("Action"),
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => history.push(`/stores/${id}`)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => setStore(id)}
          />
        </Space>
      ),
    },
  ];
  const getStoreId = () => {
    return doc(collection(db, "posts")).id;
  };

  return (
    <div key="store">
      <Row justify="end" style={{ marginBottom: "1rem" }}>
        <Button
          type="primary"
          color="primary"
          onClick={() => history.push(`stores/${getStoreId()}`)}
        >
          追加
        </Button>
      </Row>
      <Tabulate
        ref={tabulateRef}
        key={users}
        columns={columns(history, users, t)}
        collectionName="Stores"
        filter={{
          key: "companyId",
          value: user?.companyId ?? "",
        }}
        loading = {loading}
        pageLimit={10}
        callbackCollections={handleCallback}
      />
      <Modal
        title={t("Delete Confirmation")}
        visible={deleteModal}
        onOk={() => deleteStore(selectedStore)}
        onCancel={() => setDeleteModal(false)}
        okText={t("confirm")}
        cancelText={t("cancel")}
      >
        <p>{t("Store Deletion Confirmation")}</p>
      </Modal>
    </div>
  );
};

export { StoreList };
