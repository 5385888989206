import React from "react";
import { ProfileComponent } from ".";
import DashboardLayout from "../Dashboard";

const MyProfile: React.FC = () => {
  return (
    <DashboardLayout>
      <ProfileComponent />
    </DashboardLayout>
  );
};
export default MyProfile;
