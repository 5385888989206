import React, { useState, useEffect, useContext } from "react";
import { Typography, Table, Space, Tooltip, Button, Tag, Select } from "antd";
import DashboardLayout from "../Dashboard";
import { AuthContext } from "../../ContextApi/AuthContext";
import moment from "moment";
import { db } from "../../firebase";
import {
  buildDataFromDocs,
  buildResultsDocs,
  catchExceptionCallback,
} from "../../utils";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import {
  prefectureList,
  japaneseLevelList,
  statusOfResidenceList,
} from "../../utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { APPLICATION_STATUS } from "./detail";

const { Title } = Typography;

const blockStyle = {
  padding: 20,
  marginBottom: 20,
  display: "flex",
  gap: 10,
};

const residentStyle = {
  width: 300,
};

const japaneseStyle = {
  width: 240,
};

const prefectureStyle = {
  width: 130,
};

const Applicants: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [users, setUsers] = useState<any>({});
  const [jobs, setJobs] = useState<any>({});
  const [search, setSearch] = useState<any>({
    residentStatus: undefined,
    japaneseLevel: undefined,
    prefecture: undefined,
  });

  const handleResidentStatusChange = (value: any) => {
    setSearch({ ...search, residentStatus: value });
  };

  const handleJapaneseLevelChange = (value: any) => {
    setSearch({ ...search, japaneseLevel: value });
  };

  const handlePrefectureChange = (value: any) => {
    setSearch({ ...search, prefecture: value });
  };

  const searchApplicants = async () => {
    try {
      setLoading(true);
      const { applicants, users, jobs } = await getApplicants();
      const searchReadyData = applicants.map((applicant: any) => {
        return {
          ...applicant,
          japaneseLevel: jobs[applicant.jobId]?.japaneseLevel,
          userJapaneseLevel: +users[applicant.uid]?.japaneseLevel?.replace("N",""),
          jobResidentRequirement: jobs[applicant.jobId]?.residentData, 
          statusOfResidence1: users[applicant.uid]?.statusOfResidence1,
          statusOfResidence2: users[applicant.uid]?.statusOfResidence2,
          prefecture: jobs[applicant.jobId]?.prefecture ?? jobs[applicant.jobId]?.address,
          userPrefecture: users[applicant.uid]?.prefecture
        };
      });
      const searchParams: any = {...search}
      if(search.japaneseLevel) {
        searchParams.japaneseLevelNumeric = +search.japaneseLevel.replace("N","")
      }
      const filteredData: any = _.chain(searchReadyData)
        .filter(
          (item: any) =>
            (searchParams.japaneseLevel &&
              (item.japaneseLevel.includes(searchParams.japaneseLevelNumeric) || 
              (searchParams.japaneseLevelNumeric !== 0 && 
                item.userJapaneseLevel !== 0 && 
                item.userJapaneseLevel <= searchParams.japaneseLevelNumeric) || 
              (searchParams.japaneseLevelNumeric === item.userJapaneseLevel))) ||
              !searchParams.japaneseLevel
        )
        .filter(
          (item: any) =>
            (searchParams.residentStatus &&
              item.statusOfResidence1 &&
              item.statusOfResidence1 === searchParams.residentStatus) ||
            (item.statusOfResidence2 &&
              item.statusOfResidence2 === searchParams.residentStatus) ||
            (Array.isArray(item.jobResidentRequirement) && item.jobResidentRequirement.includes(searchParams.residentStatus)) ||
            !searchParams.residentStatus
        )
        .filter(
          (item: any) =>
            (searchParams.prefecture && (item.prefecture === searchParams.prefecture || item.userPrefecture === searchParams.prefecture)) ||
            !searchParams.prefecture
        )
        .value();
      setApplicants(filteredData);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  const { Option } = Select;

  const columns = [
    {
      title: t("Application Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => (
        <> {moment.unix(date.seconds).format("MMMM Do YYYY")}</>
      ),
    },
    {
      title: t("Name"),
      dataIndex: "uid",
      key: "uid",
      render: (id: string) => <>{users[id]?.name}</>,
    },
    {
      title: t("Job title"),
      dataIndex: "jobId",
      key: "jobId",
      render: (id: string) => {
        return <>{jobs[id]?.title ?? jobs[id]?.titleEn}</>;
      },
    },

    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let item = APPLICATION_STATUS.find((item) => item.value === status);
        return (
          <Space size="middle">
            <Tag color={item?.color || "yellow"}>
              {item?.label || t("Pending")}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: t("Action"),
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <Space>
          <Link to={`applications/${id}`}>
            <Tooltip title="detail">
              <Button shape="default" icon={<EditOutlined />} />
            </Tooltip>
          </Link>
        </Space>
      ),
    },
  ];

  const getApplicants = async () => {
    const q = query(
      collection(db, "Applications"),
      where("companyId", "==", user?.companyId)
    );
    const applicantsSnapshot = await getDocs(q);
    const applicantsList = buildResultsDocs(applicantsSnapshot);
    const promisesUsers: any = [];
    const promisesJobs: any = [];
    applicantsList.map(
      (applicant: any) =>
        applicant.uid &&
        promisesUsers.push(getDoc(doc(db, "Users", applicant.uid)))
    );
    applicantsList.map(
      (applicant: any) =>
        applicant.jobId &&
        promisesJobs.push(getDoc(doc(db, "Jobs", applicant.jobId)))
    );
    const usersSnapshots = await Promise.all(promisesUsers);
    const jobsSnapshots = await Promise.all(promisesJobs);
    return {
      applicants: applicantsList,
      users: buildDataFromDocs(usersSnapshots),
      jobs: buildDataFromDocs(jobsSnapshots),
    };
  };

  useEffect(() => {
    const getApplicantList = async () => {
      try {
        setLoading(true);
        const { applicants, jobs, users } = await getApplicants();
        setApplicants(applicants);
        setUsers(users);
        setJobs(jobs);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };

    getApplicantList();
  }, [user]);

  return (
    <DashboardLayout>
      <Title level={4}>{t("Applicant Management")}</Title>
      <div style={blockStyle}>
        <Select
          showSearch
          allowClear
          placeholder={t("Resident Status")}
          id="resident-status"
          value={search.residentStatus}
          onChange={handleResidentStatusChange}
          style={residentStyle}
        >
          {statusOfResidenceList.map((status: string, index: any) => (
            <Option value={status} key={`status_${index}`}>
              {t(status)}
            </Option>
          ))}
        </Select>

        <Select
          showSearch
          allowClear
          id="japaneseLevel"
          value={search.japaneseLevel}
          placeholder={t("Japanese Level")}
          onChange={handleJapaneseLevelChange}
          style={japaneseStyle}
        >
          {japaneseLevelList.map((level: string, index: any) => (
            <Option value={level} key={`level_${index}`}>
              {t(level)}
            </Option>
          ))}
        </Select>

        <Select
          showSearch
          allowClear
          id="prefecture"
          value={search.prefecture}
          placeholder={t("Prefecture")}
          onChange={handlePrefectureChange}
          style={prefectureStyle}
        >
          {prefectureList.map((prefecture: string, index: any) => (
            <Option value={prefecture} key={`prefecture_${index}`}>
              {t(prefecture)}
            </Option>
          ))}
        </Select>

        <Button
          color="primary"
          disabled={loading ? true : false}
          onClick={searchApplicants}
        >
          探す
        </Button>
      </div>
      <Table dataSource={applicants} columns={columns} loading={loading} />
    </DashboardLayout>
  );
};

export default Applicants;
