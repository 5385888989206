import React, { useContext } from 'react';
import { Result } from "antd";
import { AuthContext } from '../ContextApi/AuthContext';
import DashboardLayout from './Dashboard';

const PageNotFound: React.FC = () => {
    const { loading, authenticated } = useContext(AuthContext);
    if (loading) {
        return null;
    }
    return (authenticated ? (
        <DashboardLayout>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        </DashboardLayout>
    ) :
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
        />
    );
};

export default PageNotFound;