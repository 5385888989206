import React, { useState } from "react";

type ContextProps = {
  currentNavbar: string;
  setCurrentNavbar: (isLoggedIn: string) => void;
};

export const DashBoardContext = React.createContext<ContextProps>(
  {} as ContextProps
);

export const DashBoardProvider = ({ children }: any) => {
  const [currentNavbar, setCurrentNavbar] = useState("jobs");

  return (
    <DashBoardContext.Provider
      value={{
        currentNavbar,
        setCurrentNavbar,
      }}
    >
      {children}
    </DashBoardContext.Provider>
  );
};
